import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux'
import { Link } from 'react-router-dom';
import store from './store'

// PAGES
import Index from './pages/Index';
import Home from './pages/Home';
import Operator from './pages/Operator';
import Product from './pages/Product';
import ReviewOrder from './pages/ReviewOrder';
import RePlaceOrder from './pages/RePlaceOrder';
import PlaceOrder from './pages/PlaceOrder';
import TransactionReview from './pages/TransactionReview';
import HowToCharge from './pages/HowToCharge';
import FAQ from './pages/FAQ';
import AboutUs from './pages/AboutUs';
import Terms from './pages/Terms';

// BLOCK
import ScrollToTop from './components/ScrollToTop';


// THEME DEFS
import 'fontsource-roboto';
import "fontsource-roboto/500.css"

// MATERIAL COMPONENTS
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2B44FF'
    },
    success: {
      main: "#0FC297"
    }
  },
  typography: {
    fontSize: 18
  },
});

class Main extends Component {

  renderFooter() {
    
    return (
      <footer className="footer">
        <Container>
          <nav>
            <Link to="/">Inicio</Link>
            <Link to="/como-recargar">Cómo recargar</Link>
            <Link to="/preguntas-frecuentes">Preguntas frecuentes</Link>
            <Link to="/acerca">Sobre nosotros</Link>
          </nav>
        </Container>
      </footer>
    )
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <CssBaseline />
          <div className="App">
            <Router>
              <ScrollToTop />
              <Switch>
                <Route path="/" exact component={Index} />
                <Route path="/terminos-y-condiciones" exact component={Terms} />
                <Route path="/:country/como-recargar" exact component={HowToCharge} />
                <Route path="/:country/preguntas-frecuentes" exact component={FAQ} />
                <Route path="/:country/acerca" exact component={AboutUs} />
                
                <Route path="/:country" exact component={Home} />
                <Route path="/:country/operador" exact component={Operator} />
                <Route path="/:country/operador/cantidad" exact component={Product} />
                <Route path="/:country/revisar-datos" exact component={ReviewOrder} />
                <Route path="/:country/pagar/:order_id"  component={RePlaceOrder} />
                <Route path="/:country/pagar" exact component={PlaceOrder} />
                <Route path="/:country/transaccion/:order_id" component={TransactionReview} />
                
              </Switch>
            </Router>
          </div>
        </Provider>
      </ThemeProvider>
    )
  }
}

export default Main