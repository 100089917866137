import React, { Component } from 'react'
import { Link } from 'react-router-dom';
// COMPONENTS
import PageMenu from '../components/PageMenu'
import CTABtn from '../components/CTABtn'
import BrandedHeader from '../components/BrandedHeader'
import Footer from '../components/Footer'

// MATERIAL
import Container from '@material-ui/core/Container';

import Countries from '../countries'

let country = ''

class FAQ extends Component {
  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this)
    country = this.props.match.params.country
    document.title = "Preguntas frecuentes " + Countries[country].name
  }
 
  handleTelInputClick = () => {
    this.props.history.push('/' + country)
  }

  onClick() {
    this.props.history.push('/operador')
  }

  render() {
    return (
      <div className="static-page">
        <BrandedHeader isHome={false} />
        <Container>
          <div className="static-page-wrapper">
            <PageMenu />
            <h2>Preguntas frecuentes</h2>
            <div className="content">
              <p>En esta página encontrarás resueltas las principales dudas que puedes tener sobre hacer recargas a teléfonos móviles o teléfonos fijos en {Countries[country].name} a través de Cryptorecargas {Countries[country].name}.</p>


              
              <p className="question">¿Cómo recargo un móvil en {Countries[country].name}?</p>
              <p>Si quieres recargar un móvil a tus familiares o amigos en {Countries[country].name} desde aquí es muy sencillo y cómodo. Ingresa el teléfono de destino y sigue los pasos en nuestra guía de <Link to={"/" + country + "/como-recargar"}>Cómo Recargar</Link></p>

              <p className="question">¿Cómo puedo hacer el seguimiento a la compra de mi recarga?</p>
              <p>Puedes hacer el seguimiento de tu compra desde el botón "Comprobar estado de la transacción" que hay en el email que te hemos enviado con el resumen de la operación.</p>

              <p className="question">¿Cómo puedo pagar la recarga?</p>
              <p>Admitimos pagos con 6 criptomonedas distintas. En este listado puedes verificar si Cryptorecargas {Countries[country].name} admite tu criptomoneda:</p>
              <ul>
                <li>- Bitcoin (BTC)</li>
                <li>- Bitcoin Cash (BCH)</li>
                <li>- Litecoin (LTC)</li>
                <li>- Ethereum (ETH)</li>
                <li>- DAI (DAI)</li>
                <li>- Ripple (XRP)</li>
              </ul>

              <p className="question">¿Hay tasa por la recarga?</p>
              <p>Sí, incurre en tarifas de servicio que dependen del producto que compre y la tasa de cambio.</p>
              
              <p className="question">¿Por qué falló mi pago?</p>
              <p>Pueden haber algunas razones por las que el pago falló</p>
              <ul>
                <li>- El saldo de tu monederos electrónicos es insuficiente. Por favor, asegúrate de tener fondos suficientes para pagar la recarga.</li>
                <li>- Tu monedero electrónico está inactivo. Por favor, asegúrate de haber activado el monedero electrónico. Tu proveedor de monedero electrónico puede darte más información.</li>
                <li>- Tus credenciales de monedero electrónico son incorrectas. Por favor, asegúrate de introducir las credenciales y los códigos de verificación correctos, si corresponde.</li>
                <li>- Tu proveedor de monedero electrónico bloquea los pagos en sitios web de otros países. Comprueba esa información con tu proveedor.</li>
              </ul>
              
              <p className="question">¿Puedo cancelar mi pago o recibir un reembolso?</p>
              <p>Si la recarga móvil ya se ha ejecutado, no se puede reembolsar. En caso de que no se haya realizado la recarga, el reembolso podrá tardar 15 días.</p>

              <p className="question">¿Puedo tener un recibo de mi pago?</p>
              <p>El recibo de tu pago se creará una vez se haya hecho efectivo el pago, y se enviará al email que nos has proporcionado en el momento de la compra.</p>

              <p className="question">He recargado un número de móvil equivocado, ¿puedo cancelar al recarga?</p>
              <p>Si aún no has hecho la transacción del pago con tus cryptos, pasado el tiempo límite establecido, no se realizará la recarga.</p>

              <p className="question">La recarga no ha llegado al teléfono</p>
              <p>Hay algunos factores por los que no se recibe la recarga. Que no se hay realizado el pago de la recarga en el tiempo límite establecido y la transacción caducara. Que el número de móvil fue escrito incorrectamente y se envió con éxito a otro móvil. Que todavía la transacción esté en curso. Si la transacción es exitosa y el móvil es correcto, pero la cantidad aún no ha llegado al móvil, escríbenos a este email info@cryptorecargas.com para investigar el caso.</p>

              <p className="question">¿Cuánto tiempo tarda en hacerse la recarga en el teléfono de {Countries[country].name}?</p>
              <p>El tiempo aproximado en el que se realiza la recarga está entre 1 y 3 días. El tiempo puede variar por distintas razones, el tipo de criptomoneda utilizada y la operadora que recibe la recarga. </p>

              <p className="question">¿Puedo recargar cualquier teléfono móvil?</p>
              <p>Sí, puede enviar créditos a iPhone o Android o a cualquier otro smartphone con tarjeta SIM prepago.</p>

              <p className="question">¿Cómo contacto con Cryptorecargas {Countries[country].name}?</p>
              <p>Puedes enviarnos un mensaje al correo electrónico: info@cryptorecargas.com</p>
              <p>
                <strong>ORDRA TECHNOLOGY, S.L.</strong>
                <br />
                VAT: B76280767<br />
                Calle Practicante Ignacio Rodriguez<br />
                Edificio IV Parque Científico y Tecnológico<br />
                35017 Las Palmas de Gran Canaria, Spain
              </p>
            </div>
          </div>
        </Container>
        <div className={"FooterCTA FooterCTA--" + this.props.country}>
          <CTABtn text="Hacer una recarga" click={this.handleTelInputClick}  />
        </div>
        <Footer country={country} />
      </div>
    )
  }
}

export default FAQ