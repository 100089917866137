import React, { Component } from 'react'

// MATERIAL
import Button from '@material-ui/core/Button'

class CTABtn extends Component {
  render() {
    return (
      <div className="CTABtnWrapper">
        <Button className="CTABtn" variant="contained" disabled={this.props.disabled} onClick={this.props.click} disableElevation color="primary">
          {this.props.text}
        </Button>
      </div>
    )
  }
}

export default CTABtn