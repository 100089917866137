import { FETCH_OPERATORS, FETCH_PRODUCTS, RESET_OPERATORS, RESET_PRODUCTS } from '../actions/types'

const initialState = {
  items: [],
  products: []
}

export default function(state = initialState, action) {
  switch(action.type) {
    case FETCH_OPERATORS: 
      return {
        ...state,
        items: action.payload
      }
    case RESET_OPERATORS: 
      return {
        ...state,
        items: []
      }
    case FETCH_PRODUCTS:
      return {
        ...state,
        products: action.payload
      }
    case RESET_PRODUCTS: 
      return {
        ...state,
        products: []
      }
    default:
      return state;
  }
}