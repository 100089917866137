import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
// ACTIONS
import { fetchOperators } from '../actions/operatorActions'
// COMPONENTS
import OperatorBtn from './OperatorBtn'
// MATERIAL
import Container from '@material-ui/core/Container'

let country = ''
class OperatorSelector extends Component {

  constructor(props) {
    super(props)
    country = this.props.match.params.country
    this.props.fetchOperators(country)
  }

  render() {
    if(Object.entries(this.props.selected).length > 0){

      let operatorlogo = '';
      if(this.props.selected.product_name != undefined){
        operatorlogo = this.props.selected.product_name;
        if(operatorlogo.includes("Digitel")){
          operatorlogo = 'digitel';
        }
        if(operatorlogo.includes("Movistar")){
          operatorlogo = 'movistar';
        }
        if(operatorlogo.includes("Movilnet")){
          operatorlogo = 'movilnet';
        }
      }
      return (
        <Container>
          <div className="OperatorSelector">
            <h3>Operador seleccionado</h3>
            <div className="Operators-wrapper">
            <button className="OperatorBtn active" disabled>
              <img src={'/operators/'+operatorlogo.toLowerCase()+'.png'} alt={this.props.selected.product_name} />
              <span>
                {operatorlogo.toUpperCase()}
              </span>
            </button>
            </div>
          </div>
        </Container>
      )
    } else {
      if(this.props.operators.length === 0){
        return (
          <Container>
            <div className="OperatorSelector">
              <h3>Cargando operadores…</h3>
              <div className="Operators-wrapper">
                <OperatorBtn isDummy={true} />
              </div>
            </div>
          </Container>
        )
      } else {
        const operatorsBtns = this.props.operators.map(item => {
          return (
            <OperatorBtn operator={item} name={item.product_name} isDummy={false} key={item.product_id} />
          )
        })
        return (
          <Container>
            <div className="OperatorSelector">
              <h3>{this.props.operators.length} Operadores disponibles</h3>
              <div className="Operators-wrapper">
                {operatorsBtns}
                <i></i>
              </div>
            </div>
          </Container>
        )
      }
    }
  }
}

const mapStateToProps = state => ({
  operators: state.operators.items
})

export default connect(mapStateToProps, { fetchOperators })(withRouter(OperatorSelector))