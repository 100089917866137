export default {
  'venezuela': {
    code: 264,
    name: 'Venezuela',
    currency: 'VES',
    intcode: 58,
    phonedigits: [8,10]
  },
  'cuba': {
    code: 59,
    name: 'Cuba',
    currency: 'CUC',
    intcode: 53,
    phonedigits: [8]
  }
}