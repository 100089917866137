import React, { Component } from 'react'
import Container from '@material-ui/core/Container'
import Legals from '../components/Legals'


class Terms extends Component {
  render() {
    return (
      <div className="static-page">
        <Container>
          <div className="BrandedHeader">
            <a href="/">
            <div className="BrandedHeader__brand">
              <h1 className="home-logo"><img src="/cryptorecargas.svg" alt="Cryptorecargas" /></h1>
            </div>
            </a>
          </div>
        </Container>
        <div className="terms">
          <Container>
            <h1 className="text-center">Términos y condiciones</h1>

            <h2>1. Introducción</h2>
            <p>La prestación de servicios, (incluyendo la facilitación de compra de saldo de, datos y llamadas móviles, internet y teléfono fijo (“recarga”), conjuntamente los “Productos” y ”Servicios” a los que usted accede a través del sitio web www.cryptorecargas.com y las aplicaciones para dispositivos móviles asociadas, presentes o futuras (en conjunto, las “Aplicaciones”) están sujeta a la aceptación por su parte de estos términos y condiciones (“Acuerdo”o “Términos”). Al utilizar las Aplicaciones, manifiesta expresamente que acepta cumplir estos términos.</p>
            <p>Lea detenidamente estos Términos antes de acceder o usar los Servicios y las Aplicaciones. Se recomienda imprimir una copia de estos Términos para futuras referencias.</p>
            <p>Si no está de acuerdo con alguno de los términos y condiciones de este acuerdo o con alguna modificación posterior de los mismos, o si, de algún modo, no queda satisfecho con el uso de las Aplicaciones o Servicios, tiene como opción: a) dejar de utilizar las Aplicaciones y/o Servicios;. Usted no dispone de ningún otro recurso, jurídico o de otra índole, excepto los mencionados en el punto a).</p>
            <h2>2. Nosotros</h2>
            <p>Ordra Technology, S.L., (en adelante Ordra) que opera con el nombre comercial de Cryptorecargas (“Cryptorecargas” o “nosotros”) es quien gestiona las Aplicaciones y proporciona los Servicios. Ordra está registrada en Las Palmas de Gran Canaria con el CIF número B76280767 y su domicilio social se encuentra en la siguiente dirección: C/ Practicante Ignacio Rodriguez S/n - Edificio P  35017  - (Las Palmas De Gran Canaria)</p>
            <p>Usted puede ponerse en contacto con Ordra usando la información de contacto facilitada en la página web o en este documento de términos y condiciones.</p>
            <h2>3. Prestación del Servicio</h2>
            <p>Para usar los Servicios, no hace falta registrarse ni abrir una Cuenta. Durante el proceso de recarga, tiene que proporcionar información personal, como su dirección de correo electrónico o su número de teléfono, e información financiera. </p>
            <p>Todos los datos que nos proporcione como cliente se tratarán conforme a nuestra Política de Privacidad.</p>
            <p>Usted admite que la información que proporcione durante el registro es verídica, exacta y completa. Si esta información no fuera correcta, nos sería imposible la prestación del servicio y notificarle el estado de las “Recargas”.</p>
            <p>Es su responsabilidad informarnos de cualquier cambio que tenga lugar en dicha información.</p>
            <p>Usted es el único responsable de cualquier actividad que tenga lugar cuando se utilice su dirección de correo electrónico, número de teléfono, incluido el uso no autorizado o erróneo de su Wallet de. Usted no debe revelar su clave privada, ya sea directa o indirectamente, a ningún tercero. Salvaguardar su clave privada de su Wallet es su responsabilidad.</p>
            <p>Si usted es menor de 16 años de edad, deberá hacernos saber que ha obtenido el consentimiento de sus padres/tutore(s) y que ellos han revisado y aceptado estos términos antes usar las Aplicaciones. Si usted es un empleado de una compañía u otra entidad, o si actúa en nombre de una compañía o entidad, debe estar autorizado para aceptar estos Términos en nombre de esa compañía o entidad.</p>
            <h2>4. Datos</h2>
            <p>Ordra cumplirá todas las obligaciones que estipula la legislación de protección de datos aplicable con respecto a la posesión de Datos relativos a usted. Los datos que recopilamos como parte de los Servicios se tratarán de acuerdo con nuestra Política de Privacidad y Política de Cookies. Estos documentos establecen el modo en el que utilizamos y protegemos la información que nos proporciona. Le recomendamos que lea detenidamente nuestra Política de Privacidad y Política de Cookies.</p>
            <h2>5. Uso y coste de los Servicios</h2>
            <p>Usted acepta usar las Aplicaciones y Servicios de acuerdo con estos Términos y cumplir con cualquier regulación aplicable en conjunto con las disposiciones que se establecen en los mismos.</p>
            <p>Usted no podrá usar las Aplicaciones o Servicios: (i) en violación de cualquier Ley, estatuto, regla o regulación; o (ii) en conexión con cualquier actividad ilegal, fraudulenta, ofensiva, violenta, inmoral o indecente; o (iii) de cualquier manera en la que se fomente, promueva, facilite o instruya a otros a cualquier actividad ilegal, fraudulenta, ofensiva, violenta, inmoral o indecente. Solo Ordra o sus proveedores, le proporcionarán los Servicios en lo que respecta a los operadores de telefonía móvil y los proveedores de otros servicios disponibles en las Aplicaciones (que están sujetos a cambios y a disponibilidad).</p>
            <p>Deberá proporcionar cierta información (por ejemplo, número de teléfono móvil que desea recargar o dirección de correo electrónico) en relación con los Servicios en las Aplicaciones. Es su responsabilidad asegurarse de que escribe correctamente la información. Para las recargas o tarjetas prepago, se le pedirá que seleccione el importe de recarga o el valor de la tarjeta prepago que desea comprar.</p>
            <p>El importe total (incluyendo todos los impuestos y cargos aplicables) que se le pedirá que abone se mostrará con claridad en las Aplicaciones antes de pedirle que confirme la transacción, cuya ejecución en ese momento será totalmente opcional. Para las recargas o tarjetas prepago, el coste variará en función del importe de recarga o el valor de la tarjeta regalo que desee comprar de acuerdo con las denominaciones que se muestran en las Aplicaciones. La recarga que compra va a ser recibida en una moneda diferente a la que eligió al realizar el pago, por lo tanto la cantidad a cobrar estará sujeta a variaciones en el cambio de la Criptomoneda/ Eur en el momento del pago y se aplicará una tasa de conversión. También se le puede cobrar una tarifa por el procesamiento seguro en línea en relación con cualquier Producto que adquiera a través de las Aplicaciones. Si decide enviar un SMS opcional al destinatario de la recarga, puede aplicarse una tarifa adicional por el mensaje.</p>
            <p>Los Servicios serán prestados por Ordra una vez que el pago se haya realizado correctamente. En algunas ocasiones, puede haber una demora hasta que las terceras partes correspondientes provean el Producto. Si ha proporcionado datos de contacto, le enviaremos un mensaje de correo electrónico o SMS de confirmación con los detalles de los Servicios tan pronto como la transacción se haya realizado correctamente. Para las recargas o tarjetas prepago, usted acepta y reconoce que Ordra solo actúa con su autorización para enviar la Recarga o Tarjeta Prepago y que el tercero pertinente solo será responsable ante usted y el destinatario en lo que respecta a la provisión de los servicios relacionados con la Recarga o Tarjeta Prepago. Tan pronto como la Recarga o Tarjeta Prepago se haya comprado, se podrán usar de inmediato y, por tanto, no se podrán reembolsar ni retirar. Con el fin de evitar que se usen los Productos en un teléfono incorrecto o electrónico equivocado, Ordra le pide que confirme que los datos del destinatario que ha proporcionado son correctos.</p>
            <p>Usted reconoce que perderá el derecho a cancelar los Servicios una vez que Ordra los haya llevado a cabo completamente. Por tanto, no tendrá derecho a solicitar ningún reembolso al amparo del reglamento de la Unión Europea de 2013 sobre los derechos de información, cancelación y otros derechos del consumidor. Tenga en cuenta que las Aplicaciones limitan el número y el valor de los Productos que se pueden adquirir o recibir en un período de tiempo específico (por ejemplo, al día, a la semana o al mes). En ocasiones se podrán aplicar otras limitaciones y exclusiones relacionadas con el uso de las Aplicaciones o la compra de los Productos.</p>
            <h2>6. El proceso</h2>
            <p>Debe asegurarse de que todas las instrucciones que proporcione a Ordra a través de las Aplicaciones serán exactas, completas y verídicas. En concreto, deberá identificar correctamente el número de teléfono o la cuenta de correo electrónico asociados a la cuenta de acceso permanente al que debe enviarse la recarga. Debe asegurarse de que cualquiera de las instrucciones que se le vuelven a mostrar para obtener su confirmación sean correctas, exactas y verídicas. Todas las instrucciones confirmadas son finales y vinculantes para usted. Ordra y/o sus proveedores de servicios no serán responsables de ninguna consecuencia relacionada con información incorrecta, inexacta o falsa que usted proporcione. Usted es el único responsable de las consecuencias que tengan lugar por no facilitar información correcta, exacta y verídica, o por no corregir la información inexacta antes de la confirmación final.</p>
            <h2>7. Transacciones realizadas con Criptomonedas.</h2>
            <p>Puede adquirir los Productos a través de los Servicios de Ordra con los siguientes métodos de pago: Bitcoin, Bitcoin Cash, Litecoin, Ethereum, DAI, Ripple, o cualquier otra Criptomoneda que esté disponible en las Aplicaciones.</p>
            <p>Tras recibir de usted una solicitud completa y correcta para llevar a cabo los Servicios, Ordra le mostrará el Wallet y los importes que tendrá que transferir en la Criptomoneda seleccionada. Recuerde que es su responsabilidad, seleccionar correctamente para la criptomoneda seleccionada, el Wallet de origen, introducir correctamente el Wallet de destino y enviar el importe solicitado excluyendo cualquier fee. Para el proceso de pago, dispondrá de 15 minutos para la realización de la transacción y envío de los fondos. Una vez recibida la confirmación de recepción del importe, se reenviará una solicitud electrónica al proveedor externo correspondiente (por ejemplo, un operador de telefonía móvil o el proveedor de una tarjeta prepago) para que proporcione los Productos por el importe transferido a favor del destinatario que usted haya designado.</p>
            <p>El proceso de envío de las Criptos es totalmente ajeno a Ordra y totalmente exento de su responsabilidad. Este podrá demorarse de acuerdo con la situación de saturación que tenga en ese momento la Blockchain correspondiente. Ordra no tiene la obligación de tomar otras medidas de autenticación o verificación de identidad más allá de las requeridas por las regulaciones aplicables o las que Ordra considere adecuadas y suficientes para proteger contra fraude o blanqueo de dinero y para mantener la seguridad y el uso correcto de las Aplicaciones para cumplir con políticas internas. Solo usted es responsable de todos los cargos que se realicen después de haberse autenticado o haber verificado su identidad correctamente.</p>
            <p>Ordra asumirá la responsabilidad en el caso de no ejecución o ejecución deficiente de los Servicios que se hayan adquirido a través de las Aplicaciones, siempre que usted actúe conforme a estos Términos y haga un uso correcto de las Aplicaciones tal como Ordra indica, y no exista falsedad, fraude o negligencia por su parte. Tal responsabilidad, si se diera el caso, estaría limitada estrictamente al importe de los Servicios no ejecutados o mal ejecutados.</p>
            <h2>8. Promociones</h2>
            <p>En ocasiones, Ordra o algunos operadores de telefonía móvil o proveedores de servicios asociados realizarán promociones en las Aplicaciones. Estas promociones estarán sujetas a términos y condiciones adicionales que se mostrarán con claridad (incluyendo la fecha de finalización de la promoción) en las Aplicaciones. Ordra no se hace responsable de las promociones que realicen operadores de telefonía móvil o proveedores de servicios asociados y usted deberá comunicarse directamente con el operador o proveedor de servicios pertinente antes de usar cualquier promoción o de confiar en sus términos y condiciones. Cualquier promoción debe ser usada en buena fe y no debe abusar de las mismas o de cualquier código promocional que sea proporcionado.</p>
            <h2>9. Sus obligaciones</h2>
            <p>La provisión y el mantenimiento de los equipos y dispositivos necesarios para acceder a las Aplicaciones correrán enteramente a su cargo. Si accede a las Aplicaciones desde un dispositivo móvil, su proveedor de telefonía móvil puede cobrarle el acceso a Internet desde el dispositivo.</p>
            <p>Puede descargar las Aplicaciones para dispositivos móviles en las tiendas de aplicaciones de forma gratuita. Usted es el único responsable de asegurarse de descargar las actualizaciones posteriores de las Aplicaciones para dispositivos móviles en la tienda de aplicaciones pertinente.</p>
            <p>Ordra se reserva el derecho a modificar los requisitos de hardware y software si es necesario para poder continuar o mejorar la prestación de los Servicios a través de las Aplicaciones para dispositivos móviles.</p>
            <p>El proceso de pago, la correcta selección de las criptomonedas, Importes, Fees, Wallet de Origen y la correcta introducción del Wallet de destino, correrán enteramente a su cargo y Ordra estará totalmente exenta de cualquier error que usted comenta en este proceso.</p>
            <p>Usted reconoce que el cumplimiento de estos Términos está orientado a minimizar el riesgo de un uso no autorizado de las Aplicaciones y el daño a usted, Ordra u otros, y por tanto, debe cumplir estrictamente los términos y condiciones que aquí se estipulan. En la medida máxima que permita la ley, usted será responsable por cualquier pérdida, coste o daño que sufra Ordra o un tercero como resultado de su incumplimiento de estos Términos.</p>
            <h2>10. Servicios e información de terceros</h2>
            <p>Es posible que algunos de los Servicios que se ponen a la venta, a través de las Aplicaciones o de otros servicios que Ordra pueda ofrecerle, los envíen, creen, proporcionen o desarrollen terceros.</p>
            <p>Ordra no comprobará la exactitud ni la integridad de la información, ni la idoneidad o la calidad de los productos y servicios ofrecidos por terceros. Cualquier Tarjeta Prepago comprada a través de los Servicios tendrá sus propios términos y condiciones, incluidas las fechas de vencimiento y otras restricciones y requisitos aplicables. Ordra le aconseja que se familiarice con dichos términos y condiciones antes de comprar cualquier Tarjeta Prepago a través de los Servicios. Usted debe informarse directamente con el proveedor externo antes de confiar en la información que éste proporcione o de realizar cualquier transacción relacionada con productos y servicios de terceros a los que se haga referencia en las Aplicaciones. Los proveedores de los Servicios son totalmente responsables por sus Productos.</p>
            <h2>11. Cancelación de los Servicios</h2>
            <p>Estos Términos se aplican a cualquier Producto que usted adquiera a través de las Aplicaciones.</p>
            <p>Ordra puede desactivar su Cuenta y retirar o suspender el uso de las Aplicaciones y/o los Servicios que en ellas se proporcionen:</p>
            <p>(i) tras notificárselo a usted con una antelación razonable;</p>
            <p>(ii) inmediatamente después de que usted incumpla estos Términos o cuando Ordra crea, razonablemente, que usted está incumpliendo los mismos;</p>
            <p>(iii) inmediatamente después de detectar su insolvencia, bancarrota o incapacidad para pagar cualquier importe debido, tanto si consta como si no en una petición judicial, formal y legítima, o cualquier otra incapacidad contractual. En estos casos, Ordra se reserva el derecho a iniciar acciones de cobro de la deuda dentro del marco legal;</p>
            <p>(iv) inmediatamente si Ordra tiene la creencia razonable de que usted ha usado las Aplicaciones y/o Servicios (a) en violación de cualquier ley, regla, estatuto o regulación; o (b) en conexión con cualquier actividad que fomente, promueva, facilite o instruya a otros a cualquier actividad ilegal, fraudulenta, ofensiva, violenta, inmoral o indecente; o (c) en incumplimiento de cualquiera de los limites en número de recargas o valor monetario establecidos por Ordra.</p>
            <p>Estos Términos no tienen duración mínima ni máxima y continuarán estando vigentes por tanto tiempo como usted tenga una cuenta con Ordra. Usted puede dejar de usar las Aplicaciones o Servicios y eliminar su Cuenta en cualquier momento y sin motivo mediante el envío a Ordra de un aviso por escrito a tal efecto, pero sin perjuicio de su responsabilidad ante cualquier deuda pendiente de alguna Cuenta o por otro motivo anterior a la fecha de la rescisión.</p>
            <p>Ordra se reserva el derecho a rechazar el procesamiento o a cancelar, con actitud razonable, cualquier transacción una vez rescindido este Acuerdo o tras suspensión o retiro de los Servicios. Ordra no es responsable por ninguna pérdida que usted pueda sufrir por no procesarse una transacción como parte de los Servicios después de rescindir el Acuerdo o suspender o retirar los Servicios.</p>
            <h2>12. Feedback</h2>
            <p>Si envía a Ordra comentarios, sugerencias, ideas u otro material en relación con, o a través de, las Aplicaciones o los Servicios proporcionados, acepta que Ordra puede usar, reproducir, publicar, modificar, adaptar y transmitir la comunicación mencionada a otros sin cargo alguno y sin restricciones, sujeto a las obligaciones de Ordra que se estipulan en nuestro Aviso de Privacidad.</p>
            <h2>13. Variaciones de los Términos</h2>
            <p>Ordra se reserva el derecho a modificar, corregir o variar estos Términos con fines comerciales o jurídicos. Cualquier variación en este sentido será efectiva y vinculante a los catorce (14) días después de haberle avisado de dicha variación por alguno de los siguientes medios: correo electrónico o mostrando un mensaje en las Aplicaciones. Tras recibir el aviso de modificación de estos Términos, usted tendrá el derecho a dejar de usar los Servicios o Aplicaciones, y/o cerrar su Cuenta de inmediato notificándoselo a Ordra por escrito sin perjuicio de su responsabilidad ante cualquier deuda de cualquier Cuenta o cualquier otra obligación, ya sea financiera, jurídica o de otro tipo, que surgiera antes de la fecha de cierre de su Cuenta.</p>
            <h2>14. Avisos</h2>
            <p>Salvo que se disponga expresamente lo contrario, cualquier aviso que deba enviar a Ordra en relación con estos Términos deberá hacerse por escrito y enviarse por correo electrónico a la dirección info@cryptorecargas.com o por correo postal a la siguiente dirección: Atención al cliente, Ordra Technology, S.L., C/ Practicante Ignacio Rodriguez s/n – Parque Científico y Tecnológico-Edificio IV  35017  - (Las Palmas De Gran Canaria)</p>
            <p>Salvo que se disponga expresamente lo contrario, cualquier aviso que Ordra deba enviarle a usted en relación con estos Términos puede enviarse por correo postal o correo electrónico.</p>
            <p>A veces y con su permiso, Ordra puede ponerse en contacto con usted para mantenerle al día sobre los Servicios de Ordra, incluidos nuevos productos, campañas y promociones. Para obtener más información, revise nuestro Aviso de Privacidad.</p>
            <h2>15. Derechos de propiedad intelectual</h2>
            <p>A los efectos de estos Términos, “derechos de propiedad intelectual” se refiere a todos los derechos de autor, patentes, marcas comerciales registradas y no registradas, derechos de diseño registrado y no registrado, derechos sobre bases de datos, derechos sobre topografía y otros derechos de propiedad intelectual, todos los derechos de recurso por usurpación, todos los derechos de solicitud de protección con respecto a cualquiera de los derechos anteriores y todas las demás formas de protección de índole similar o que tengan un efecto equivalente o similar sobre cualquiera de estos que permanezcan en cualquier parte del mundo.</p>
            <p>Usted solo puede descargar, utilizar, ver y mostrar las Aplicaciones (y los derechos de propiedad intelectual correspondientes) con el fin de utilizar los Servicios y conforme a los términos de estos Términos. Salvo que se especifique lo contrario, los derechos de propiedad intelectual y el contenido de las Aplicaciones son propiedad de Ordra. Queda prohibida la reproducción, copia, modificación, alteración o adaptación parcial o total del contenido de las Aplicaciones (incluidos los gráficos o marcas comerciales) de cualquier forma sin el consentimiento previo de Ordra, excepto en los casos en los que tiene la autorización de Ordra para imprimir o descargar dicho contenido para uso personal, no comercial.</p>
            <p>Ordra es propietario también de todos los derechos de propiedad intelectual que existen en los nombres de dominio www.cryptorecargas.com y sus sitios subsidiarios. Ordra se toma muy en serio la ciberocupación. Si tiene sospecha de algún incidente, le rogamos que nos lo notifique en la siguiente dirección: info@cryptorecargas.com</p>
            <h2>16. Suspensión de los Servicios</h2>
            <p>En el caso de interrupción, fallo, no disponibilidad o mal funcionamiento de (o relacionado con) cualquier producto o sistema que se utilice en relación con las Aplicaciones o los Servicios, o si existe algún riesgo de seguridad real o posible, Ordra tendrá derecho, sin incurrir en ninguna responsabilidad hacia usted, a suspender temporalmente los Servicios pertinentes o el acceso a las Aplicaciones o su Cuenta durante un período razonable que sea necesario para remediar o solucionar el problema. Ordra puede suspender también el acceso a las Aplicaciones y/o Cuenta y/o los Servicios cuando sea necesario para realizar trabajos de mantenimiento (ya sea planeado o por una emergencia) o actualización. Sin perjuicio de los derechos de Ordra establecidos en la sección 12, usted también acepta y reconoce que su acceso a las Aplicaciones y/o su Cuenta y/o o los Servicios pueden ser inmediatamente suspendidos si Ordra cree, razonablemente, que usted ha usado las Aplicaciones y/o Servicios (a) en violación de cualquier Ley, estatuto, regla o regulación: o (b) de cualquier manera en la que se fomente, promueva, facilite o instruya a otros a cualquier actividad ilegal, fraudulenta, ofensiva, violenta, inmoral o indecente; o (c) en incumplimiento de cualquiera de los limites en número de recargas o valor monetario establecidos por Ordra. En caso de tal suspensión y a su entera discreción, Ordra podrá restablecer el acceso a su Cuenta y reanudar la provisión de los Servicios a usted.</p>
            <h2>17. Causa de Fuerza Mayor</h2>
            <p>Ordra no será responsable ni estará incumpliendo sus obligaciones con respecto a estos Términos si deja de ejercer sus funciones y obligaciones total o parcialmente por causas de fuerza mayor, incendio, decreto gubernamental, estatal o de otro organismo, guerra, conmoción civil, insurrección, embargo, incapacidad para comunicarse con terceros por cualquier motivo, fallo de algún ordenador, red o sistema de liquidación, fallo o demora en alguna red de telefonía móvil, impedimento o dificultad para obtener tiempo de conexión (saldo), energía u otros suministros, conflictos laborales de cualquier naturaleza, pago tardío o equivocado por parte de un agente o por cualquier otra razón (similar o no a las mencionadas aquí) que se escape al control de Ordra.</p>
            <h2>18. Disponibilidad, Seguridad y Mantenimiento</h2>
            <p>Usted consiente, reconoce y acepta que las comunicaciones electrónicas, Internet, las líneas telefónicas o los medios de telecomunicaciones basados en SMS pueden no ser seguros y las comunicaciones por estos medios pueden ser interceptadas por personas no autorizadas o entregadas de forma incorrecta. Por consiguiente, Ordra no puede garantizar la privacidad ni la confidencialidad de las comunicaciones que se lleven a cabo por estos medios, aunque tomará las medidas apropiadas (y procurará que sus proveedores de servicios así lo hagan) para proteger estos métodos de comunicación.</p>
            <p>A veces, puede ser necesario o deseable por razones de seguridad, mantenimiento (planeado o por una emergencia), actualización u otros motivos:</p>
            <ul>
              <li>interrumpir la disponibilidad de las Aplicaciones o los Servicios; y/o</li>
              <li>demorar la implementación de algunos Servicios nuevos; y/o</li>
              <li>retirar, reemplazar o volver a emitir su contraseña; y/o</li>
              <li>cambiar los procedimientos o procesos de autenticación para acceder a las Aplicaciones o los Servicios, haciendo un esfuerzo razonable por minimizar los inconvenientes causado</li>
            </ul>
            <p>Usted reconoce y acepta que estos sucesos pueden ocurrir y que Ordra no es responsable de ello. Si Ordra cambia los procedimientos de autenticación para acceder a las Aplicaciones o a los Servicios que en ellas se ofrecen, sin perjuicio de los demás términos de este Contrato, Ordra puede incorporar estos procedimientos facilitándole instrucciones en las Aplicaciones para las que se van a implementar tales procedimientos.</p>
            <h2>19. Cesión</h2>
            <p>Usted admite que ha aceptado estos Términos para su propio beneficio y no para el beneficio de otra persona, y que no puede subcontratar ni transferir los derechos u obligaciones que se le reconocen en estos Términos.</p>
            <h2>22. Limitación de Responsabilidad</h2>
            <p>Ordra no se hace responsable del uso de las Aplicaciones y/o Servicios en conexión con cualquier actividad no autorizada, ilegal, fraudulenta, ofensiva, violenta, inmoral o indecente. Salvo lo estipulado en la sección 7, Ordra, sus directivos, empleados o agentes rechazan toda responsabilidad por cualquier tipo de pérdida o daños que pueda sufrir usted o algún tercero (incluidos, sin limitarse a ellos, los daños directos, indirectos, incidentales, especiales o consecuentes, de carácter ejemplar o punitivo, o cualquier pérdida de ingresos, dinero, datos o fondo de comercio) en relación con el uso que usted haga de las Aplicaciones, Productos o los Servicios. Esto no limita de ningún modo nuestra responsabilidad por muerte o daños personales causados por negligencia nuestra o por algún otro asunto que, en virtud de la ley, no podríamos excluir de nuestra responsabilidad.</p>
            <p>Los únicos daños y perjuicios en los que incurrirá Ordra son los compensatorios limitados, estrictamente, al importe de la recarga u otro valor pagado por los Servicios que se proporcionan a través de las Aplicaciones, cuando la culpa recaiga únicamente en Ordra. Usted no tiene ningún derecho a recibir ninguna indemnización de Ordra.</p>
            <p>Además, Ordra no tendrá ninguna responsabilidad independiente, indirecta, sobre terceros, en el caso de que usted no cumpla los términos y condiciones que se estipulan o a los que se hace referencia en este documento o en otros sitios web relacionados o vinculados operados de forma independiente por terceros.</p>
            <p>Usted acepta y reconoce que las Aplicaciones y los Servicios, y el contenido que ofrecen se proporcionan “tal cual”. En la medida máxima que permita la ley, Ordra no ofrece ninguna garantía en cuanto al uso y la disponibilidad de las Aplicaciones y los Servicios que en ellas se proporcionan.</p>
            <h2>21. Legislación aplicable</h2>
            <p>Estos Términos, las Aplicaciones y la provisión de los Servicios se rigen por las leyes de España. En el caso de una reclamación o un conflicto que surjan a raíz de estos Términos y/o el uso de las Aplicaciones o los Servicios, usted acepta que los tribunales de Las Palmas de Gran Canaria (España) tendrán jurisdicción exclusiva sobre tales reclamaciones o conflictos, sin perjuicio de los derechos que le confiere la legislación aplicable.</p>
            <h2>22. Disociabilidad</h2>
            <p>Si en algún momento, alguna disposición de estos Términos (o parte de ellos) es o pasa a ser ilegal, no válida o no aplicable, esto no afectará ni disminuirá la legalidad, validez o aplicabilidad del resto de estos Términos (incluido el resto de la disposición donde solo una parte es o pasa a ser ilegal, no válida o no aplicable).</p>
            <h2>23. Exención de responsabilidad</h2>
            <p>Cualquier renuncia de responsabilidad por incumplimiento u omisión de alguna de las disposiciones de estos Términos no se interpretará como una renuncia de responsabilidad por un incumplimiento posterior de la misma disposición o de otras disposiciones. De igual modo, ninguna demora u omisión por parte de Ordra para ejercer o hacer valer algún derecho, poder o privilegio supondrá una exención de responsabilidad por algún incumplimiento u omisión por parte de usted.</p>
            <h2>24. Atención al cliente e información de contacto</h2>
            <p>Si tiene alguna duda o queja sobre estos Términos, o si necesita ayuda con las Aplicaciones o los Servicios, estamos aquí siempre para ayudarle. Puede ponerse en contacto con nosotros en horario ininterrumpido, todos los días del año, por los medios que se indican a continuación. Las reclamaciones se pueden presentar también en la plataforma de Resolución de litigios en línea de la Comisión Europea, disponible <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=ES" target="_blank" rel="noopener noreferrer">aquí</a>.</p>
            <p>Por correo electrónico: info@cryptorecargas.com</p>
          </Container>
          <Legals />
        </div>
        
        
      </div>
    )
  }
}

export default Terms