import React from 'react';
import Main from './Main';
import './App.scss';

function App() {
  return (
    <Main />
  );
}

export default App;
