import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
// COMPONENTS
import TopBarModal from './TopBarModal'
// MATERIAL
import Container from '@material-ui/core/Container'
// ICONS
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/HighlightOff'

/**
 * PROPS:
 * hasBack (bool) if true, shows back arrow; else shows check icon
 * hasModal (bool) if true, shows Help icon that triggers modal
 * text (string) the text
 */

class TopBar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showModal: false
    }
    this.goBack = this.goBack.bind(this)
    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
  }

  goBack(){
    this.props.history.push('/' + this.props.country)
  }

  showModal() {
    this.setState({
      showModal: true
    })
  }

  hideModal() {
    this.setState({
      showModal: false
    })
  }

  renderBackIcon() {
    if(this.props.hasBack){
      return (
        <div className="TopBar__action TopBar__action--arrow" onClick={this.goBack}>
          <ArrowBackIcon />
          <span>Volver</span>
        </div>
      )
    } else {
      return (
        <div className="TopBar__action TopBar__action--check">
          <CheckIcon />
        </div>
      )
    }
  }

  renderFAQModal() {
    if(this.props.hasModal){
      return (
        <div className="TopBar__modal-trigger" onClick={this.showModal}>
          <span>Ayuda</span>
          <HelpOutlineIcon />
        </div>
      )
    }
  }

  render() {
    return (
      <Container>
        <div className={this.state.showModal ? 'TopBarModal show' : 'TopBarModal'}>
          <div className="TopBarModalOverlay" onClick={this.hideModal} />
          <TopBarModal country={this.props.country} />
          <div className="TopBarModalClose" onClick={this.hideModal}>
            <CloseIcon className="hide-modal-btn"  />
          </div>
        </div>
        <div className="TopBar">
          { this.renderBackIcon() }
          <div className="TopBar__text">
            { this.props.text }
          </div>
          { this.renderFAQModal() }
        </div>
      </Container>
    )
  }
}

export default withRouter(TopBar)