import { FETCH_CURRENCIES } from '../actions/types'

const initialState = {}

export default function(state = initialState, action) {
  switch(action.type) {
    case FETCH_CURRENCIES: 
      return {
        ...state,
        currencies: action.payload
      }
    default:
      return state;
  }
}