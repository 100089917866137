import { combineReducers } from 'redux'
import orderReducer from './orderReducer'
import operatorReducer from './operatorReducer'
import currencyReducer from './currencyReducer'


export default combineReducers({
  order: orderReducer,
  operators: operatorReducer,
  currencies: currencyReducer
})