import { FETCH_CURRENCIES, SET_ORDER_CURRENCY, SET_ORDER_RATE } from './types'


export const fetchCurrencies = () => (dispatch, getState) => {
  // const proxyUrl = 'https://cors-anywhere.herokuapp.com/'
  const proxyUrl = ''
  fetch(proxyUrl + 'https://api.cryptorecargas.com/api/CryptoCoin')
    .then(
      res => res.json()
    )
    .then(function(currencies){
      dispatch({
        type: FETCH_CURRENCIES,
        payload: currencies
      })
      const state = getState()
      if(state.order.currency.coin_short_name === undefined){
        dispatch({
          type: SET_ORDER_CURRENCY,
          payload: currencies[1]
        })  
      }
      dispatch({
        type: SET_ORDER_RATE,
        payload: currencies[0].rate_btc
      })
    })
}