import React from 'react'
import {withRouter} from "react-router"
import { Link } from 'react-router-dom';
// MATERIAL UI
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu'
// ICONS
import MenuItem from '@material-ui/core/MenuItem'
import MenuIcon from '@material-ui/icons/Menu'



function PageMenu(props) {

  const country = props.match.params.country

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="page-menu">
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <span>Menú</span> <MenuIcon color="primary" />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={handleClose}>
          <Link to={"/" + country}>Inicio</Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to={"/" + country + "/como-recargar"}>Cómo recargar</Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to={"/" + country + "/preguntas-frecuentes"}>Preguntas frecuentes</Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to={"/" + country + "/acerca"}>Sobre nosotros</Link>
        </MenuItem>
      </Menu>
    </div>
  )
}
export default withRouter(PageMenu)