import React, { Component } from 'react'

// COMPONENTS
import TopBar from '../components/TopBar'
import TelInput from   '../components/TelInput'
import OperatorSelector from   '../components/OperatorSelector'
import CoinSelector from   '../components/CoinSelector'
import SelectorDummy from '../components/SelectorDummy'
import Footer from '../components/Footer'
// ICONS
import ArrowUpwardTwoToneIcon from '@material-ui/icons/ArrowUpwardTwoTone'


let country = ''
class Operator extends Component {

  constructor(props) {
    super(props)
    country = this.props.match.params.country
    document.title = "Operadores |  Cryptorecargas " + country
  }

  render() {
    return (
      <div className="OperatorPage">
        <div className="secondaryOpacityBg">
          <TopBar text="Recargas" hasBack={true} hasModal={true} country={country} />
          <TelInput view="inner" text="Número de teléfono" />
          <OperatorSelector selected={{}} />
          <CoinSelector render={false} />
        </div>
        <div className="dummy-wrapper">
          <SelectorDummy country={country} />
          <div className="overlay" />
          <p className="info-text"><ArrowUpwardTwoToneIcon className="dummy-info-icon icon-blink" /><br />Por favor, selecciona primero el operador</p>
        </div>
        <Footer country={country} />
      </div>
    )
  }
}
export default Operator