import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import BrandedHeader from '../components/BrandedHeader'
import Footer from '../components/Footer'

// MATERIAL UI
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'

// Icons
import LoopIcon from '@material-ui/icons/Loop'
import Countries from '../countries'

const states = {
  0: {
    operation: {
      icon: 'operation_0.svg',
      title: 'Operación',
      titleClass: '',
      description: 'No iniciada.'
    },
    charge: {
      icon: 'charge_0.svg',
      title: 'Recarga',
      titleClass: '',
      description: 'No iniciada.'
    }
  },
  1: {
    operation: {
      icon: 'operation_1.svg',
      title: 'Operación …',
      titleClass: 'success',
      description: 'Esperando la recepción del pago.'
    },
    charge: {
      icon: 'charge_1.svg',
      title: 'Recarga',
      titleClass: '',
      description: 'No iniciada, pendiente de aprobación de la operación.'
    }
  },
  2: {
    operation: {
      icon: 'operation_1.svg',
      title: 'Operación …',
      titleClass: 'success',
      description: 'Esperando la validación y aceptación de la operación.'
    },
    charge: {
      icon: 'charge_1.svg',
      title: 'Recarga',
      titleClass: '',
      description: 'No iniciada, pendiente de aprobación de la operación.'
    }
  },
  3: {
    operation: {
      icon: 'operation_2.svg',
      title: 'Operación',
      titleClass: 'success',
      description: 'Aprobada en la Blockchain.'
    },
    charge: {
      icon: 'charge_1.svg',
      title: 'Recarga …',
      titleClass: 'success',
      description: 'En proceso.'
    }
  },
  4: {
    operation: {
      icon: 'operation_2.svg',
      title: 'Operación',
      titleClass: 'success',
      description: 'Aprobada en la Blockchain.'
    },
    charge: {
      icon: 'charge_2.svg',
      title: 'Recarga',
      titleClass: 'success',
      description: 'Recarga realizada con éxito.'
    }
  },
  5: {
    operation: {
      icon: 'operation_3.svg',
      title: 'Operación',
      titleClass: '',
      description: 'La operación no ha sido aprobada en la Blockchain.'
    },
    charge: {
      icon: 'charge_3.svg',
      title: 'Recarga',
      titleClass: '',
      description: 'No se podrá realizar la recarga en el teléfono.'
    }
  },
  6: {
    operation: {
      icon: 'operation_3.svg',
      title: 'Operación',
      titleClass: '',
      description: 'No se ha recibido el pago dentro del plazo y la operación ha caducado.'
    },
    charge: {
      icon: 'charge_3.svg',
      title: 'Recarga',
      titleClass: '',
      description: 'No se podrá realizar la recarga en el teléfono.'
    }
  }
}

let country = ''
let orderId = ''
class TransactionReview extends Component {
  constructor(props) {
    super(props)
    this.toHome = this.toHome.bind(this)
    this.toReplace = this.toReplace.bind(this)
    country = this.props.match.params.country
    document.title = "Revisa tu transacción | Cryptorecargas " + Countries[country].name
    
    this.state = {
      currState: -1,
    }

    const order_id = this.props.match.params.order_id
    orderId = order_id
    // const proxyUrl = 'https://cors-anywhere.herokuapp.com/'
    const proxyUrl = ''
    let self = this
    fetch(proxyUrl + 'https://api.cryptorecargas.com/api/Order/' + order_id)
      .then(res => res.json())
      .then(function(response){
        let newState = -1
        if(response.order_status === "Receive...") {
          newState = 0
          self.showState(newState)
        } else if(response.order_status === "Waiting Payment...") {
          newState = 0
          self.showState(newState)
        } else if(response.order_status === "Waiting Transfers..."){
          newState = 2
          self.showState(newState)
        } else if(response.order_status === "Timeout Payment...") {
          newState = 6
          self.showState(newState)
        } else if(response.order_status === "Completed...") {
          newState = 4
          self.showState(newState)
        } else {
          alert('Ha ocurrido un error. Por favor, contacte con nosotros a través de nuestro email info@cryptorecargas.com o en nuestro canal de Telegram https://t.me/cryptorecargas')
        }
      })
    
  }

  showState(newState) {
    this.setState({
      currState: newState
    })
    let self = this
    if(newState !== 4 && newState !== 6) {
      setTimeout(function() {
        // const proxyUrl = 'https://cors-anywhere.herokuapp.com/'
        const proxyUrl = ''
        fetch(proxyUrl + 'https://api.cryptorecargas.com/api/Order/' + orderId)
          .then(res => res.json())
          .then(function(response){
            let newState = -1
            if(response.order_status === "Receive...") {
              newState = 0
              self.showState(newState)
            } else if(response.order_status === "Waiting Payment...") {
              newState = 0
              self.showState(newState)
            } else if(response.order_status === "Waiting Transfers..."){
              newState = 2
              self.showState(newState)
            }
          })
      }, 30000);
    }
  }

 

  toHome() {
    this.props.history.push('/')
  }

  toReplace(order_id) {
    const country = this.props.match.params.country
    this.props.history.push('/' + country + '/pagar/' + order_id)
  }

  renderBtn() {
    if(this.state.currState === 0){
      return (
        <>
          <p style={{textAlign: 'center', marginBottom: 16}}>Es posible que la red esté saturada. Si has realizado la operación y no se ha reflejado como tal, por favor, espera unos minutos.</p>
          <Button className="place-order-cta-btn" onClick={() => {this.toReplace(this.props.match.params.order_id)}} variant="outlined" color="primary">VOLVER A DATOS DE PAGO</Button>
        </>
      )
    } else {
      return(
        <Button className="place-order-cta-btn" onClick={this.toHome} variant="outlined" color="primary">IR A INICIO</Button>
      )
    }
  }

  render() {
    if(this.state.currState >= 0){
      return (
        
        <div className="transaction-review">
          <BrandedHeader isHome={false} />
          <Container>
            <div className="transaction-review-info">
              <h2>Estado de la transacción</h2>
              <div className="transaction-review-info-data">
                <div className="review-row">
                  <div className="separator" />
                  <div className="img">
                    <img src={'/transactions/' + states[this.state.currState].operation.icon } alt="" />
                  </div>
                  <div className="info">
                    <p className={"title " + states[this.state.currState].operation.titleClass} >{states[this.state.currState].operation.title}</p>
                    <p className="description">{states[this.state.currState].operation.description}</p>
                  </div>
                </div>
                <div className="review-row">
                  <div className="img">
                    <img src={'/transactions/' + states[this.state.currState].charge.icon } alt="" />
                  </div>
                  <div className="info">
                    <p className={"title " + states[this.state.currState].operation.titleClass} >{states[this.state.currState].charge.title}</p>
                    <p className="description">{states[this.state.currState].charge.description}</p>
                  </div>
                </div>
              </div>
              <div className="place-order-cta-btn-wrapper">
                {this.renderBtn()}
              </div>
            </div>
          </Container>
        </div>
      )
    } else {
      return (
        <div className="transaction-review">
          <BrandedHeader isHome={false} />
          <Container>
            <div className="transaction-review-info">
              <h2>Estado de la transacción</h2>
              <div className="transaction-review-info-data">
                <div className="review-row">
                  <div className="separator" />
                  <div className="img">
                    <img src='/transactions/operation_0.svg' alt="" />
                  </div>
                  <div className="info">
                    <p className="title" >----------</p>
                    <p className="description">----------</p>
                  </div>
                </div>
                <div className="review-row">
                  <div className="img">
                    <img src='/transactions/charge_0.svg' alt="" />
                  </div>
                  <div className="info">
                    <p className="title">----------</p>
                    <p className="description">----------</p>
                  </div>
                </div>
              </div>
              <div className="place-order-cta-btn-wrapper">
                <Button className="place-order-cta-btn" variant="outlined" color="primary">IR A INICIO</Button>
              </div>
            </div>
            <div className="dummy-wrapper">
              <div className="overlay" />
              <p className="info-text">Cargando información<br/><LoopIcon className="icon-spin dummy-info-icon" /><br />Por favor, espere unos instantes.</p>
            </div>
          </Container>
          <Footer country={country} />
        </div>
      )
    }
  }
}

export default withRouter(TransactionReview)