import React, { Component } from 'react'
import { connect } from 'react-redux'
import CurrencyFormat from 'react-currency-format'
// ACTIONS
import { setOrderAmount } from '../actions/orderActions'

class ProductBtn extends Component {

  selectProduct(product) {
    this.props.setOrderAmount({
      euros: product.costValue,
      crypto: product.costCurrency,
      cryptoUnit: product.costCurrencyUnit,
      local: product.faceValue,
      face_value_id: product.faceValueId
    })
  }

  render() {
    const suff = this.props.product.costCurrencyUnit === 'DAI' ? ' + Fees' : ''
    return (
      <button
        id={'product_' + this.props.product.key}
        onClick={() => {this.selectProduct(this.props.product)}}
        className={this.props.order.amount.local === this.props.product.faceValue ? 'ProductBtn active' : 'ProductBtn '}
      >
        <div className="bullet">
          <span />
        </div>
        <div className="label">
          <p className="local">
            <CurrencyFormat
              value={this.props.product.faceValue}
              displayType={'text'}
              suffix={' '+this.props.product.faceCurrency}
              thousandSeparator={"."}
              fixedDecimalScale={true}
              decimalScale={2}
              decimalSeparator={","}  />
            </p>
          <p className="crypto">
            <CurrencyFormat
              value={this.props.product.costCurrency}
              displayType={'text'}
              suffix={' ' + this.props.product.costCurrencyUnit + suff}
              thousandSeparator={"."}
              fixedDecimalScale={true}
              decimalScale={5}
              decimalSeparator={","}  />
            </p>
        </div>
      </button>
    )
  }
}

const mapStateToProps = state => ({
  order: state.order
})

export default connect(mapStateToProps, { setOrderAmount })(ProductBtn)