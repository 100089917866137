import { FETCH_OPERATORS, RESET_OPERATORS, FETCH_PRODUCTS, RESET_PRODUCTS } from './types'
import Countries from '../countries'

export const fetchOperators = (country) => dispatch => {
  // const proxyUrl = 'https://cors-anywhere.herokuapp.com/'
  const proxyUrl = ''
  fetch(proxyUrl + 'https://api.cryptorecargas.com/api/Products?country_id='+Countries[country].code+'&category_id=14')
    .then(res => res.json())
    .then(operators => 
      dispatch({
        type: FETCH_OPERATORS,
        payload: operators
      })
    )
}

export const resetOperators = () => dispatch => {
  dispatch({
    type: RESET_OPERATORS,
    payload: []
  })
}

export const fetchProducts = () => (dispatch, getState) => {
  const state = getState()
  dispatch({
    type: RESET_PRODUCTS,
    payload: []
  })
  // const proxyUrl = 'https://cors-anywhere.herokuapp.com/'
  const proxyUrl = ''
  fetch(proxyUrl + 'https://api.cryptorecargas.com/api/Products/info?product_id=' + state.order.operator.product_id)
    .then(
      res => res.json()
    )
    .then(products => 
      dispatch({
        type: FETCH_PRODUCTS,
        payload: products
      })
    )
}

export const resetProducts = () => dispatch => {
  dispatch({
    type: RESET_PRODUCTS,
    payload: []
  })
}
