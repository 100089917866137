import React, { Component } from 'react'
import Container from '@material-ui/core/Container'
import { Link } from 'react-router-dom'

class Footer extends Component {
  render() {
    return (
      <footer className={"footer footer--" + this.props.country}>
        <Container>
          <nav>
            <Link to="/">Inicio</Link>
            <Link to={"/" + this.props.country + "/como-recargar"}>Cómo recargar</Link>
            <Link to={"/" + this.props.country + "/preguntas-frecuentes"}>Preguntas frecuentes</Link>
            <Link to={"/" + this.props.country + "/acerca"}>Sobre nosotros</Link>
            <a href="/terminos-y-condiciones">Términos y condiciones</a>
            <a href="https://www.iubenda.com/privacy-policy/24180238" target="_blank" rel="noopener noreferrer" className="iubenda-nostyle no-brand iubenda-embed" title="Privacy Policy ">Política de privacidad</a>
          </nav>
        </Container>
      </footer>
    )
  }
}

export default Footer