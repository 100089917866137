import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

// ACTIONS
import { setOrderOperator } from '../actions/orderActions'

class OperatorBtn extends Component {
  constructor(props){
    super(props)
    this.state = {
      active: ''
    }
    this.onClick = this.handleOperatorClick.bind(this)
  }

  handleOperatorClick(operator) {
    this.setState({
      active: operator
    })
    this.props.setOrderOperator(operator)
    if(this.props.order.beneficiary.length === 8 || this.props.order.beneficiary.length === 10){
      const country = this.props.match.params.country
      this.props.history.push('/' + country + '/operador/cantidad')
    } else {
      alert('Por favor, revisa el número de teléfono.')
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }

  render() {
    let operatorlogo = '';
    if(this.props.name != undefined){
      operatorlogo = this.props.name;
      if(operatorlogo.includes("Digitel")){
        operatorlogo = 'digitel';
      }
      if(operatorlogo.includes("Movistar")){
        operatorlogo = 'movistar';
      }
      if(operatorlogo.includes("Movilnet")){
        operatorlogo = 'movilnet';
      }
    }
    
    if(this.props.isDummy){
      return (
        <button className="OperatorBtn OperatorBtn--dummy" disabled>
          <img className="flag" width="22" src="/operators/blank.jpg" alt="Cryptorecargas" />
          <span>
            ------
          </span>
        </button>
      )
    } else {
      return (
        <button className={this.props.operator === this.props.order.operator ? "OperatorBtn active" : "OperatorBtn"} onClick={() => {this.handleOperatorClick(this.props.operator)}} >
          <img src={'/operators/'+operatorlogo.toLowerCase()+'.png'} alt={this.props.name} />
          <span>
            {operatorlogo.toUpperCase()}
          </span>
        </button>
      )
    }
  }
}

const mapStateToProps = state => ({
  order: state.order
})

export default connect(mapStateToProps, { setOrderOperator })(withRouter(OperatorBtn))