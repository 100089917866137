import React, { Component } from 'react'
import { connect } from 'react-redux'
// ACTIONS
import { setOrderEmail } from '../actions/orderActions'
// MATERIAL COMPS
import Container from '@material-ui/core/Container'
import Input from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
// ICONS
import CheckIcon from '@material-ui/icons/Check'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';


class EmailFields extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      email2: '',
      email1Ok: false,
      email2Ok: false,
      email2Class: ''
    }
    this.onChange = this.onChange.bind(this)
    this.onChange2 = this.onChange2.bind(this)
    this.onBlur2 = this.onBlur2.bind(this)
    this.onFocus2 = this.onFocus2.bind(this)
  }

  onChange(e) {
    const valid = this.validateEmail(e.target.value)
    this.setState({email: e.target.value});
    this.props.setOrderEmail('')
    this.setState({
      email2: '',
      email2Ok: false,
      email2Class: ''
    })
    if(valid){
      this.setState({
        email1Ok: true
      })
    } else {
      this.setState({
        email1Ok: false
      })
    }
  }

  onChange2(e) {
    const valid = this.validateEmail(e.target.value)
    this.setState({email2: e.target.value});
    if(valid && e.target.value === this.state.email){
      this.setState({
        email2Ok: true
      })
      this.props.setOrderEmail(this.state.email)
    } else {
      this.props.setOrderEmail('')
      this.setState({
        email2Ok: false
      })
    }
  }

  onBlur2(e) {
    if(!this.state.email2Ok) {
      this.setState({
        email2Class: 'warning'
      })
    } else {
      this.setState({
        email2Class: ''
      })
    }
  }

  onFocus2(e) {
    this.setState({
      email2Class: ''
    })
  }

  validateEmail(email) {
    const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[]\.,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
    return re.test(email);
  }

  renderConfirmEmail() {
    if(this.state.email1Ok){
      return (
        <Input
          type="email"
          placeholder="Repite tu email"
          className={this.state.email2Class} 
          value={this.state.email2} 
          onChange={this.onChange2}
          onBlur={this.onBlur2}
          onFocus={this.onFocus2}
          endAdornment={<InputAdornment position="end" className={this.state.email2Ok ? 'email-validator validated' : 'email-validator'}><CheckIcon className="check" /><ErrorOutlineIcon className="warning" /></InputAdornment>}
        />
      )
    }
  }

  render() {
    return (
      <Container>
        <div className="EmailFields">
          <h3>Tu email</h3>
          <p>Te enviaremos el detalle completo de la operación.</p>
          <Input
            type="email"
            placeholder="tuemail@tuemail.com" 
            value={this.state.email}
            onChange={this.onChange}
            endAdornment={<InputAdornment position="end" className={this.state.email1Ok ? 'email-validator validated' : 'email-validator'}><CheckIcon /></InputAdornment>}
          />
          {this.renderConfirmEmail()}
          <p className={'warning-text ' + this.state.email2Class}>Tu email no coincide. Por favor revísalo para poder continuar.</p>
        </div>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  order: state.order
})
export default connect(mapStateToProps, {setOrderEmail})(EmailFields)