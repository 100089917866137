import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import CurrencyFormat from 'react-currency-format'
import {CopyToClipboard} from 'react-copy-to-clipboard';

import TopBar from '../components/TopBar'
import Footer from '../components/Footer'

// ACTIONS
import { setOrderID } from  '../actions/orderActions'

// MATERIAL UI
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'

// Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import LoopIcon from '@material-ui/icons/Loop'
import CloseIcon from '@material-ui/icons/Close';

import Countries from '../countries'

let country = ''
class PlaceOrder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transaction: {},
      showTimeout : true
    }
    country = this.props.match.params.country
    if(props.order.beneficiary.trim() === '') {
      props.history.push('/' + country + '/')
    } else if(props.order.orderid.trim() !== '') {
      props.history.push('/' + country + '/transaccion/' + props.order.orderid)
    } else {
      document.title = "Realiza tu pago | Cryptorecargas " + Countries[country].name
      const requestBody = {
        country_id: Countries[country].code,
        product_id: props.order.operator.product_id,
        face_value_id: props.order.amount.face_value_id,
        face_value: props.order.amount.local,
        face_value_currency: Countries[country].currency,
        email: props.order.email,
        beneficiary_phone: Countries[country].intcode + props.order.beneficiary,
        coin_short_name: props.order.amount.cryptoUnit,
        operation_amount_EUR: props.order.amount.euros
      }
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
      }
      // const proxyUrl = 'https://cors-anywhere.herokuapp.com/'
      const proxyUrl = ''
      let self = this
      fetch(proxyUrl + 'https://api.cryptorecargas.com/api/Order', requestOptions)
        .then(res => res.json())
        .then(function(response){
          self.setState({
            transaction: response
          })
          props.setOrderID(response.order_id)
        })
    }
    this.onClick = this.onClick.bind(this)
    this.dismiss = this.dismiss.bind(this)
  }

  dismiss() {
    this.setState({
      showTimeout : false
    })
  }

  onClick(order_id) {
    if(order_id !== undefined){
      this.props.history.push('/' + country + '/transaccion/' + order_id)
    }
  }

  renderinfo(amount){
    const fee = amount - this.props.order.amount.crypto
    if (fee > 0) {
      return (
        <>
        <p style={{textAlign: 'right'}}>
          <span style={{marginRight: 8}}>Ingreso: </span>
          <CurrencyFormat
            value={this.props.order.amount.crypto}
            displayType={'text'}
            suffix={' ' + this.props.order.amount.cryptoUnit }
            thousandSeparator={"."}
            fixedDecimalScale={true}
            decimalScale={5}
            decimalSeparator={","}  />
        </p>
        <p style={{textAlign: 'right'}}>
          <span style={{marginRight: 8}}>Fees: </span>
          <CurrencyFormat
            value={amount - this.props.order.amount.crypto}
            displayType={'text'}
            suffix={' ' + this.props.order.amount.cryptoUnit }
            thousandSeparator={"."}
            fixedDecimalScale={true}
            decimalScale={5}
            decimalSeparator={","}  />
        </p>
        <p style={{textAlign: 'right'}}>
          <span style={{marginRight: 8}}>Total: </span>
          <strong>
          <CurrencyFormat
            value={parseFloat(amount)}
            displayType={'text'}
            suffix={' ' + this.props.order.amount.cryptoUnit }
            thousandSeparator={"."}
            fixedDecimalScale={true}
            decimalScale={5}
            decimalSeparator={","}  />
          </strong>
        </p>
        </>
      )
    }
  }

  renderTransactionInfo() {

    if(typeof(this.state.transaction.order_cryto_qr) !== 'undefined') {
      const fullad = this.state.transaction.order_cryto_address
      const amount = this.state.transaction.order_cryto_amount


      return (
        <div className="place-order-transaction-info">
          <img src={ this.state.transaction.order_cryto_qr } alt="QRCode" />
          <p style={{textAlign: 'center', fontSize: 14}}>{ fullad }</p>
          <div className="place-order-copy-wrapper">
            <CopyToClipboard text={fullad} style={{marginBottom: 16}}>
              <Button className="place-order-copy-btn" variant="contained" color="primary">COPIAR DIRECCIÓN</Button>
            </CopyToClipboard>
            <CopyToClipboard text={amount}>
              <Button className="place-order-copy-btn" variant="contained" color="primary">COPIAR CANTIDAD</Button>
            </CopyToClipboard>
            {this.renderinfo(amount)}
          </div>
        </div>
      )
    } else {
      return (
        <div className="dummy-wrapper relative">
          <p className="info-text" style={{lineHeight: 1.4}}>Cargando datos de la transacción<br/><LoopIcon className="icon-spin dummy-info-icon" /><br />Por favor, espere unos instantes.</p>
        </div>
      )
    }
  }

  renderBtn() {
    if(this.state.transaction.order_id !== undefined) {
      return (
        <Button className="place-order-cta-btn" onClick={() => {this.onClick(this.state.transaction.order_id)}} variant="outlined" color="primary" >YA PAGUÉ</Button>
      )
    }
  }

  render() {
    const suff = this.props.order.amount.cryptoUnit === 'DAI' ? ' + Fees' : ''
    let icon = ''
    if(this.props.order.operator.product_name !== undefined){
      icon = this.props.order.operator.product_name.toLowerCase()
    }
    return (
      <div className="place-order">
        <Container>
          <TopBar text="Realiza tu pago" hasBack={false} hasModal={false} country={country} />
        </Container>
        <div className={this.state.showTimeout ? 'place-order-timeout show' : 'place-order-timeout hide'}>
          <Container>
            <div className="place-order-timeout-wrapper">
              <CloseIcon onClick={this.dismiss} />
              <p className="header">Tienes 15 minutos para pagar.</p>
              <p>Pasado ese tiempo la operación caducará y deberás iniciar todo el proceso nuevamente.</p>
            </div>
          </Container>
        </div>
        <Container>
          <div className="order-review-wrapper">
            { this.renderTransactionInfo() }
            <div className="place-order-details">
            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <h3>Detalle de la recarga</h3>
              </AccordionSummary>
              <AccordionDetails>
                <h4>Número de teléfono</h4>
                <p className="field-value">+{Countries[country].intcode} {this.props.order.beneficiary}</p>
                <hr />
                <div className="order-review-operator">
                  <div>
                    <h4>Operador</h4>
                    <p className="field-value">{this.props.order.operator.product_name}</p>
                  </div>
                  <div>
                    <img src={'/operators/'+icon+'.png'} alt={this.props.order.operator.product_name} />
                  </div>
                </div>
                <hr />
                <h4>Cantidad de recarga</h4>
                <div className="order-review__amounts">
                  <p className="local">
                    <CurrencyFormat
                      value={this.props.order.amount.local}
                      displayType={'text'}
                      suffix={' '+Countries[country].currency}
                      thousandSeparator={"."}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      decimalSeparator={","}  />
                  </p>

                  <p className="crypto">
                    <CurrencyFormat
                      value={this.props.order.amount.crypto}
                      displayType={'text'}
                      suffix={' ' + this.props.order.amount.cryptoUnit + suff}
                      thousandSeparator={"."}
                      fixedDecimalScale={true}
                      decimalScale={5}
                      decimalSeparator={","}  />
                  </p>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          </div>
          
          <div className="place-order-cta-btn-wrapper">
            {this.renderBtn()}
          </div>
        </Container>
        <Footer country={country} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    order: state.order,
    operators: state.operators
  };
};

export default connect(mapStateToProps, { setOrderID })(withRouter(PlaceOrder))