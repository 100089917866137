import React, { Component } from 'react'
import { connect } from 'react-redux'
// ACTIONS
import { fetchCurrencies } from '../actions/currenciesActions'
import { setOrderCurrency, setOrderAmount } from '../actions/orderActions'
// MATERIAL
import Container from '@material-ui/core/Container'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'

class CoinSelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedCurrency: ''
    }
    if(Object.keys(this.props.order.currency).length === 0){
      this.props.fetchCurrencies()
    }
    this.onChange = this.onChange.bind(this)
  }

  onChange(e) {
    this.setState({selectedCurrency: e.target.value});
    const selectedCurr = this.props.currencies.currencies.findIndex(p => p.coin_short_name === e.target.value)
    this.props.setOrderCurrency(this.props.currencies.currencies[selectedCurr])
    this.props.setOrderAmount({
      euros: 0,
      crypto: 0,
      cryptoUnit: e.target.value,
      local: 0
    })
  }

  componentDidMount() {
    if(this.props.render){
      if(this.state.selectedCurrency === '' && Object.keys(this.props.order.currency).length > 0){
        this.setState({
          selectedCurrency: this.props.order.currency.coin_short_name
        })
      } else {
        if(Object.keys(this.props.currencies).length > 0){
          this.setState({
            selectedCurrency: this.props.currencies.currencies[1]
          })
          this.props.setOrderCurrency(this.props.currencies.currencies[1])
          this.props.setOrderAmount({
            euros: 0,
            crypto: 0,
            cryptoUnit: this.props.currencies.currencies[1].coin_short_name,
            local: 0
          })
        }
      }
    }
  }
  

  renderOptions() {
    if(this.props.currencies.currencies !== undefined && this.props.currencies.currencies.length >= 0){
      const cryptos = this.props.currencies.currencies
      return cryptos.map((item) => {
        if(item.coin_long_name.toLowerCase() !== 'euro') {
          return (
            <MenuItem key={item.coin_short_name} value={item.coin_short_name}>
              <img height="32" alt={item.coin_long_name} src={'/cryptos/icon/' + item.coin_short_name.toLowerCase() + '@2x.png'} />
              <strong>{item.coin_short_name}</strong>
              {item.coin_long_name}
            </MenuItem>
          )
        } else {
          return (
            <div key="-1" />
          )
        }
      })
    }
  }

  render() {
    if(this.props.render) {
      return (
        <Container>
          <div className="CoinSelector">
            <h3>Pagar en:</h3>
            <FormControl variant="outlined" >
              <Select
                onChange={this.onChange}
                value={this.state.selectedCurrency}
              >
                { this.renderOptions() }
              </Select>
            </FormControl>
          </div>
        </Container>
      )
    } else {
      return(<div />)
    }
  }
}

const mapStateToProps = state => ({
  currencies: state.currencies,
  order: state.order
})

export default connect(mapStateToProps, { fetchCurrencies, setOrderCurrency, setOrderAmount })(CoinSelector)