import React, { Component } from 'react'

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';

let country = ''
class HomeComodity extends Component {

  constructor(props) {
    super(props)
    country = this.props.country
  }

  render() {
    return (
      <div className={"home-comodity home-comodity--" + country}>
        <div className="home-comodity-wrapper">
          <Container>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={6}>
                <img src={"/illustrations/home-comodity--" + country + ".svg"} alt={"Cryptorecargas | Muy fácil y cómodo"} />
              </Grid>
              <Grid item xs={12} md={6}>
                <h2>Muy fácil y cómodo</h2>
                <ul>
                  <li>
                    <CheckIcon fontSize="small" />
                    En solo 2 pasos. 
                  </li>
                  <li>
                    <CheckIcon fontSize="small" />
                    Desde cualquier lugar.
                  </li>
                  <li>
                    <CheckIcon fontSize="small" />
                    Paga con las principales criptomonedas.
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    )
  }
}

export default HomeComodity