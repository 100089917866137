import { RESET_ORDER, SET_ORDER_BENEFICIARY, SET_ORDER_OPERATOR, SET_ORDER_CURRENCY, SET_ORDER_RATE, SET_ORDER_AMOUNT, SET_ORDER_EMAIL, SET_ORDER_ID } from '../actions/types'

const initialState = {
  beneficiary: '',
  operator: {},
  currency: {},
  rate: {},
  amount: {
    crypto: 0,
    euros: 0,
    cryptoUnit: '',
    local: 0,
    face_value_id: ''
  },
  email: '',
  orderid: ''
}

export default function(state = initialState, action) {
  switch(action.type) {
    case RESET_ORDER:
      return {
        ...state,
        beneficiary: '',
        operator: {},
        currency: {},
        rate: {},
        amount: {
          crypto: 0,
          euros: 0,
          cryptoUnit: '',
          local: 0,
          face_value_id: ''
        },
        email: '',
        orderid: ''
      }
    case SET_ORDER_BENEFICIARY:
      return {
        ...state,
        beneficiary: action.payload
      }
    case SET_ORDER_OPERATOR:
      return {
        ...state,
        operator: action.payload
      }
    case SET_ORDER_CURRENCY:
      return {
        ...state,
        currency: action.payload
      }
    case SET_ORDER_RATE:
      return {
        ...state,
        rate: action.payload
      }
    case SET_ORDER_AMOUNT:
      return {
        ...state,
        amount: action.payload,
        face_value_id: action.face_value_id
      }
    case SET_ORDER_EMAIL:
      return {
        ...state,
        email: action.payload
      }
    case SET_ORDER_ID:
      return {
        ...state,
        orderid: action.payload
      }
    default:
      return state;
  }
}