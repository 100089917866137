import React, { Component } from 'react'

// COMPONENTS
import BrandedHeader from '../components/BrandedHeader'
import TelInput from   '../components/TelInput'
import FooterCTA from '../components/FooterCTA'
import HomeComodity from '../components/HomeComodity'
import HomeSecureCharge from '../components/HomeSecureCharge'
import HomeInfo from '../components/HomeInfo'
import Footer from '../components/Footer'

import Countries from '../countries'

let country = ''
class Home extends Component {

  constructor(props) {
    super(props)
    country = this.props.match.params.country
    document.title = "Cryptorecargas " + Countries[country].name
  }
  
  render() {
    return (
      <div>
        <BrandedHeader isHome={true} countryName={Countries[country].name} />
        <div className={'home-illustration--01 home-illustration--01--' + country} />
        <TelInput view="home" text="Dinos el número a recargar:" />
        <HomeComodity country={country} />
        <HomeSecureCharge country={country} />
        <HomeInfo country={country} countryName={Countries[country].name} />
        <FooterCTA country={country} />
        <Footer country={country} />
      </div>
    )
  }
}

export default Home