import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
// COMPONENTS
import CTABtn from './CTABtn'
// ACTIONS
import { setOrderBeneficiary } from '../actions/orderActions'
// MATERIAL
import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

import Countries from '../countries'

/**
 * PROPS:
 * location (string) true when HOME (styling stuff)
 * text (string) the text
 * number (int) phone number for dummy
 */

const styles = theme => ({

  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderWidth: '1px',
      borderRadius: '4px',
      borderColor: '#AFB9C5 !important'
    }
  },
  cssFocused: {
    borderWidth: '1px',
    borderRadius: '4px',
    borderColor: '#AFB9C5 !important'
  },
  notchedOutline: {
    borderWidth: '1px',
    borderRadius: '4px',
    borderColor: '#AFB9C5 !important'
  },

});

let country = ''
class TelInput extends Component {
  constructor(props) {
    super(props)
    let defaultVal = this.props.beneficiary;
    this.state = {
      beneficiary: defaultVal,
      focused: false,
      inputValid: false,
      inputClass: ''
    }
    this.onChange = this.onChange.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    country = this.props.match.params.country
  }

  componentDidMount() {
    if(Countries[country].phonedigits.includes(this.props.beneficiary.length)) {
      this.setState({
        inputValid: true,
        inputClass: ''
      })
    } else {
      this.setState({
        inputValid: false,
        inputClass: ''
      })
    }
  }

  onChange(e) {
    if (Number.isInteger(+e.target.value) || e.target.value === '') {
      if(e.target.value.length <= Math.max.apply(Math, Countries[country].phonedigits)){
        this.setState({
          beneficiary: e.target.value,
          inputClass: ''
        })
        this.props.setOrderBeneficiary(e.target.value)
        if(Countries[country].phonedigits.includes(e.target.value.length)) {
          this.setState({
            inputValid: true,
            inputClass: ''
          })
        } else {
          this.setState({
            inputValid: false,
            inputClass: ''
          })
        }
      }
    }
  }

  onFocus(e) {
    this.setState({
      focused: true
    })
  }

  onBlur(e) {
    this.setState({
      focused: false
    })
  }

  handleTelInputClick = () => {
    if(Countries[country].phonedigits.includes(this.props.beneficiary.length)) {
      this.setState({
        inputClass: ''
      })
      const country = this.props.match.params.country
      this.props.history.push('/' + country + '/operador')
    } else {
      this.setState({
        inputClass: 'error'
      })
    }
  }

  renderButton() {
    if(this.props.view === 'home' && (this.state.beneficiary !== '' || this.state.focused)) {
      return (
        <CTABtn text="RECARGAR" click={this.handleTelInputClick} disabled={ this.state.inputValid ? false : true } />
      )
    }
  }

  render() {
    const { classes } = this.props
    return (
      <Container>
        <div className={"TelInput TelInput--" + this.props.view}>
          <p className="TelInputLabel">{this.props.text}</p>
          <TextField
            id="TelInput"
            type="tel"
            variant="outlined"
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            disabled={this.props.view === 'dummy' ? true : false}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              },
              autoComplete: 'off',
              startAdornment: <InputAdornment position="start">+{ Countries[country].intcode } <img className="flag" width="22" src={"/resources/flag_"+country+".svg"} alt={"Cryptorecargas " + Countries[country].name} /></InputAdornment>,
            }}
            value={this.props.view === 'dummy' ? this.props.number : this.state.beneficiary }
          />
          {this.renderButton()}
        </div>
      </Container>
    )
  }
}

TelInput.propTypes = {
  classes: PropTypes.object.isRequired,
  setOrderBeneficiary: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  beneficiary: state.order.beneficiary
})

export default connect(mapStateToProps, {setOrderBeneficiary})(withStyles(styles)(withRouter(TelInput)))