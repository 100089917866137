import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
// MATERIAL
import Container from '@material-ui/core/Container'

import Countries from '../countries'

/**
 * PROPS:
 * isHome (bool) true when HOME (styling stuff)
 */
let country = ''
class BrandedHeader extends Component {

  constructor(props){
    super(props)
    country = this.props.match.params.country
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.props.history.push('/')
  }

  render() {
    return (
      <Container>
        <div className={['BrandedHeader', 'BrandedHeader--' + country, this.props.isHome ? 'BrandedHeader--home' : 'BrandedHeader--inner'].join(' ')}>
          <div className="BrandedHeader__brand" onClick={this.handleClick}>
            <img src={'/resources/flag_' + country + '.svg'} alt={'Cryptorecargas ' + Countries[country].name } width="36" />
            <h1>Cryptorecargas <span>{this.props.countryName}</span></h1>
          </div>
          <nav className="BrandedHeader__nav">
            <Link to="/">Inicio</Link>
            <Link to={"/" + country + "/como-recargar"}>Cómo recargar</Link>
            <Link to={"/" + country + "/preguntas-frecuentes"}>Preguntas frecuentes</Link>
            <Link to={"/" + country + "/acerca"}>Sobre nosotros</Link>
          </nav>
        </div>
      </Container>
    )
  }
  
}

export default withRouter(BrandedHeader)