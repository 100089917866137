import React, { Component } from 'react'
// COMPONENTS
import PageMenu from '../components/PageMenu'
import CTABtn from '../components/CTABtn'
import BrandedHeader from '../components/BrandedHeader'
import Footer from '../components/Footer'
// MATERIAL
import Container from '@material-ui/core/Container';

import Countries from '../countries'

let country = ''
class About extends Component {
  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this)
    country = this.props.match.params.country
    document.title = "Acerca de Cryptorecargas " + Countries[country].name
  }
  
  onClick() {
    this.props.history.push('/operator')
  }
 
  handleTelInputClick = () => {
    this.props.history.push('/' + country)
  }

  render() {
    return (
      <div className="static-page">
        <BrandedHeader isHome={false} />
        <Container>
          <div className="static-page-wrapper">
            <PageMenu />
            <h2>Sobre nosotros</h2>
            <img className="about-us-img" src={"/illustrations/about-us--" +country+ ".svg"} alt={"Sobre nosotros | Cryptorecargas " + Countries[country].name} />
            <div className="content">
              <p>Cryptocharge es una marca de ORDRA TECHNOLOGY, S.L. con sede en Las Palmas de Gran Canaria, Spain, que nace de su experiencia en los sectores de las finanzas, la tecnología y la seguridad criprográfica en los últimos 20 años, con el objetivo de ofrecer servicios de recargas electrónicas para los usuarios de las principales operadoras de telefonía móvil y fija en Venezuela y en Cuba.</p>
              <h3>Atención al cliente cuando lo necesites</h3>
              <p>Comunícate con nosotros por correo electrónico. Estamos aquí para ayudarte con cualquier duda que tengas sobre las recargas.</p>
              <p><strong>ORDRA TECHNOLOGY, S.L.</strong></p>
              <p>
                <a href="mailto:info@cryptorecargas.com">info@cryptorecargas.com</a><br />
                VAT: B76280767<br />
                Calle Practicante Ignacio Rodriguez<br />
                Edificio IV Parque Científico y Tecnológico<br />
                35017, Las Palmas de Gran Canaria, Spain<br />
              </p>
            </div>
          </div>
        </Container>
        <div className={"FooterCTA FooterCTA--" + this.props.country}>
          <CTABtn text="Hacer una recarga" click={this.handleTelInputClick}  />
        </div>
        <Footer country={country} />
      </div>
    )
  }
}

export default About