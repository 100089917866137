import React, { Component } from 'react'

export default class FeesExplain extends Component {
  render() {
    return (
      <div style={{maxWidth: 800, margin: '0 auto 60px', fontSize: 16}}>
        <strong>¿Qué son los Fees?</strong>
        <p style={{marginTop: 8}}>Los fees o comisión son cantidades de criptomonedas asignadas a los mineros para que procesen una transacción.</p>
      </div>
    )
  }
}
