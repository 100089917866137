import { RESET_ORDER, SET_ORDER_BENEFICIARY, SET_ORDER_OPERATOR, SET_ORDER_CURRENCY, SET_ORDER_AMOUNT, SET_ORDER_EMAIL, SET_ORDER_ID } from './types'

export const resetOrder = (telNum) => dispatch => {
  dispatch({
    type: RESET_ORDER,
    payload: telNum
  })
}

export const setOrderBeneficiary = (telNum) => dispatch => {
  dispatch({
    type: SET_ORDER_BENEFICIARY,
    payload: telNum
  })
}

export const setOrderOperator = (operator) => dispatch => {
  dispatch({
    type: SET_ORDER_OPERATOR,
    payload: operator
  })
}

export const setOrderCurrency = (currency) => dispatch => {
  dispatch({
    type: SET_ORDER_CURRENCY,
    payload: currency
  })
}

export const setOrderAmount = (amount) => dispatch => {
  dispatch({
    type: SET_ORDER_AMOUNT,
    payload: amount
  })
}

export const setOrderEmail = (email) => dispatch => {
  dispatch({
    type: SET_ORDER_EMAIL,
    payload: email
  })
}

export const setOrderID = (orderid) => dispatch => {
  dispatch({
    type: SET_ORDER_ID,
    payload: orderid
  })
}