import React, { Component } from 'react'
import { connect } from 'react-redux'
// COMPONENTS
import ProductBtn from   './ProductBtn'
// ACTIONS
import { fetchProducts } from '../actions/operatorActions'
// MATERIAL
import Container from '@material-ui/core/Container'
// Icons
import LoopIcon from '@material-ui/icons/Loop'

class ProductSelector extends Component {
  constructor(props) {
    super(props)
    if(Object.keys(this.props.order.operator).length > 0){
      this.props.fetchProducts()
    }
  }

  buildProducts = () => {
    const p = this.props.operator.products[0]
    let values = [];
    const order = this.props.order
    if(p.isFixed){
      for(let i = 0; i < this.props.operator.products.length; ++i) {
        const prod = this.props.operator.products[i]
        values.push({
          key: i,
          faceValue: prod.face_value,
          faceValueId: prod.face_value_id,
          faceCurrency: prod.face_value_currency,
          costValue: prod.cost,
          costBTC: (prod.cost  * order.rate),
          costCurrency: this.round((prod.cost  * order.rate / order.currency.rate_btc), 5),
          costCurrencyUnit: order.currency.coin_short_name
        })
      }
      /*
      let range = 10
      for(let i = 1; i <= range; ++i) {
        values.push({
          key: i,
          faceValue: (p.face_value) * i,
          faceCurrency: p.face_value_currency,
          costValue: (p.cost) * i,
          costBTC: (p.cost  * order.rate) * i,
          costCurrency: (p.cost  * order.rate / order.currency.rate_btc) * i,
          costCurrencyUnit: order.currency.coin_short_name
        })
      }
      */
    } else {
      const p = this.props.operator.products[0]
      let range = 6
      if(p.maximum_cost > 40) {
        range = 8
      }
      for(let i = 1; i <= range; ++i) {
        const face = ((p.face_value_to - p.face_value_from) * (i /range)) + p.face_value_from
        const faceRounded = Math.round(face / p.face_value_step) * p.face_value_step
        const cost = ((p.maximum_cost - p.minimum_cost) * (i / range)) + p.minimum_cost
        const costFixed = cost * faceRounded / face;
        const costBTC = costFixed * order.rate
        const costCurrency = costBTC / order.currency.rate_btc
        values.push({
          key: i,
          faceValue: this.round(faceRounded),
          faceValueId: p.face_value_id,
          faceCurrency: p.face_value_currency,
          costValue: this.round(costFixed),
          costBTC: costBTC,
          costCurrency: costCurrency,
          costCurrencyUnit: order.currency.coin_short_name
        })
      }
    }
    return values;
  }

  round = (value, decimals = 2) => (
    Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
  )

  renderProducts() {
    if(Object.keys(this.props.operator.products).length === 0){
      return (
        <div>
          <div className="dummy-wrapper">
            <div className="ProductSelector">
              <Container>
                <h3>¿Cuánto quieres recargar?</h3>
                <div className="ProductsWrapper">
                  <div>
                    <button className="ProductBtn">
                      <div className="bullet">
                        <span />
                      </div>
                      <div className="label">
                        <p className="local">---------- ---</p>
                        <p className="crypto">---------- BTC</p>
                      </div>
                    </button>
                  </div>
                  <div>
                    <button className="ProductBtn">
                      <div className="bullet">
                        <span />
                      </div>
                      <div className="label">
                        <p className="local">---------- ---</p>
                        <p className="crypto">---------- BTC</p>
                      </div>
                    </button>
                  </div>
                  <div>
                    <button className="ProductBtn">
                      <div className="bullet">
                        <span />
                      </div>
                      <div className="label">
                        <p className="local">---------- ---</p>
                        <p className="crypto">---------- BTC</p>
                      </div>
                    </button>
                  </div>
                  <div>
                    <button className="ProductBtn">
                      <div className="bullet">
                        <span />
                      </div>
                      <div className="label">
                        <p className="local">---------- ---</p>
                        <p className="crypto">---------- BTC</p>
                      </div>
                    </button>
                  </div>
                  <div>
                    <button className="ProductBtn">
                      <div className="bullet">
                        <span />
                      </div>
                      <div className="label">
                        <p className="local">---------- ---</p>
                        <p className="crypto">---------- BTC</p>
                      </div>
                    </button>
                  </div>
                  <div>
                    <button className="ProductBtn">
                      <div className="bullet">
                        <span />
                      </div>
                      <div className="label">
                        <p className="local">---------- ---</p>
                        <p className="crypto">---------- BTC</p>
                      </div>
                    </button>
                  </div>
                  <div>
                    <button className="ProductBtn">
                      <div className="bullet">
                        <span />
                      </div>
                      <div className="label">
                        <p className="local">---------- ---</p>
                        <p className="crypto">---------- BTC</p>
                      </div>
                    </button>
                  </div>
                  <div>
                    <button className="ProductBtn">
                      <div className="bullet">
                        <span />
                      </div>
                      <div className="label">
                        <p className="local">---------- ---</p>
                        <p className="crypto">---------- BTC</p>
                      </div>
                    </button>
                  </div>
                </div>
              </Container>
            </div>
            <div className="overlay" />
            <p className="info-text">Cargando opciones de recarga<br/><LoopIcon className="icon-spin dummy-info-icon" /><br />Por favor, espere unos instantes.</p>
          </div>
        </div>
      )
    } else {
      const values = this.buildProducts()
      const prods = values.map((product, index) => {
        if(values[index + 1] !== undefined && (values[index + 1].costCurrency === product.costCurrency)){
          return(
            <></>
          )
        } else {
          return (
            <div key={product.key}>
              <ProductBtn product={product} />
            </div>
          )
        }
      })
      return (
        <div className="ProductSelector">
          <Container>
            <h3>¿Cuánto quieres recargar?</h3>
            <div className="ProductsWrapper">
              {prods}
            </div>
          </Container>
        </div>
      )
    }
  }

  render() {
    return (
      <div>
        {this.renderProducts()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  order: state.order,
  operator: state.operators
})
export default connect(mapStateToProps, {fetchProducts})(ProductSelector)