import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

// COMPONENTS
import TopBar from '../components/TopBar'
import TelInput from   '../components/TelInput'
import OperatorSelector from   '../components/OperatorSelector'
import CoinSelector from   '../components/CoinSelector'
import ProductSelector from   '../components/ProductSelector'
import EmailFields from   '../components/EmailFields'
import CTABtn from   '../components/CTABtn'
import Footer from '../components/Footer'
import FeesExplain from '../components/FeesExplain'

let country = ''
class Product extends Component {
  constructor(props) {
    super(props)
    this.state = {
      termsAccepted: false
    }
    country = this.props.match.params.country
    document.title = "Productos |  Cryptorecargas " + country
  }
  
  componentWillMount() {
    if(Object.entries(this.props.order.operator).length === 0) {
      this.props.history.push('/' + country + '/operador')
    }
  }

  handleCTAClick = () => {
    country = this.props.match.params.country
    this.props.history.push('/' + country + '/revisar-datos')
  }

  setAccepted = (prevState) => {
    this.setState(prevState => ({
      termsAccepted: !prevState.termsAccepted
    }));
  }

  render() {
    const Fees = this.props.order.currency.coin_short_name === 'DAI' ? <FeesExplain /> : <></>
    return (
      <div className="ProductPage">
        <div className="secondaryOpacityBg">
          <TopBar text="Recargas" hasBack={true} hasModal={true} country={country} />
          <TelInput view="dummy" text="Número de teléfono" number={this.props.order.beneficiary} />
          <OperatorSelector selected={this.props.order.operator} />
          <CoinSelector render={true} />
        </div>
          <ProductSelector />
          {Fees}
        <div className={"secondaryBg secondaryBg--" + country}>
          <EmailFields />
          <div className="check_terms">
            <label>
              {this.state.termsAccepted}
              <input type="checkbox" onChange={this.setAccepted}/>
              He leído y acepto los <a href="/terminos-y-condiciones" target="_blank" rel="noopener noreferrer">términos y condiciones</a>
            </label>
          </div>
          <CTABtn text="pagar" click={this.handleCTAClick} disabled={this.state.termsAccepted && this.props.order.amount.euros !== 0 && this.props.order.email !== '' ? false : true} />
        </div>
        <Footer country={country} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  order: state.order
})

export default connect(mapStateToProps, {})(withRouter(Product))