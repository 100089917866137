import React, { Component } from 'react'
import Container from '@material-ui/core/Container'

class Legals extends Component {
  render() {
    return (
      <Container class="legals-links">
        <a href="/terminos-y-condiciones">Términos y condiciones</a>

        <a href="https://www.iubenda.com/privacy-policy/24180238" class="iubenda-nostyle no-brand iubenda-embed" title="Privacy Policy ">Política de privacidad</a>
      </Container>
    )
  }
}

export default Legals