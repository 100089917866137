import React, { Component } from 'react'
// COMPONENTS
import PageMenu from '../components/PageMenu'
import Footer from '../components/Footer'
import CTABtn from '../components/CTABtn'
// import ReactPlayer from 'react-player'
import BrandedHeader from '../components/BrandedHeader'
// MATERIAL
import Container from '@material-ui/core/Container'
// import Grid from '@material-ui/core/Grid';
// ICONS
import CheckIcon from '@material-ui/icons/Check'

import Countries from '../countries'

let country = ''

class HowToCharge extends Component {
  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this)
    country = this.props.match.params.country
    document.title = "Cómo recargar " + Countries[country].name
  }
 
  handleTelInputClick = () => {
    this.props.history.push('/' + country)
  }

  onClick() {
    this.props.history.push('/operador')
  }

  render() {
    return (
      <div className="static-page">
        <BrandedHeader isHome={false} />
        <Container>
          <div className="static-page-wrapper">
            <PageMenu />
            <h2>Cómo recargar</h2>
            <div className="content">
            <p>Si quieres recargar teléfonos en {Countries[country].name}, Cryptorecargas {Countries[country].name} tiene el servicio de recargas más seguro y a los principales operadores de telefonía del país.</p>
            <p>Podrás hacerlo muy cómodamente desde tu móvil o desde tu ordenador de escritorio. En muy pocos pasos y muy sencillos.</p>
            {/* 
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <h3>Cómo recargar desde tu móvil</h3>
                <div className='player-wrapper'>
                  <ReactPlayer
                    className='react-player'
                    url='https://www.youtube.com/watch?v=ysz5S6PUM-U'
                    width='100%'
                    height='100%'
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <h3>Cómo recargard desde tu computadora</h3>
                <div className='player-wrapper'>
                  <ReactPlayer
                    className='react-player'
                    url='https://www.youtube.com/watch?v=ysz5S6PUM-U'
                    width='100%'
                    height='100%'
                  />
                </div>
              </Grid>
            </Grid>
            */}  
            <ul>
              <li><CheckIcon /> Añade el teléfono que quieres recargar.</li>
              <li><CheckIcon /> Elige el operador.</li>
              <li><CheckIcon /> Selecciona la criptomoneda con la que pagarás.</li>
              <li><CheckIcon /> Puedes elegir entre varios paquetes de recarga predefinidos.</li>
              <li><CheckIcon /> Revisa que todos los datos están correctos y valida la operación.</li>
              <li><CheckIcon /> Tienes 15 minutos para hacer el pago y ya solo queda esperar a que todo el proceso siga su curso en la Blockchain.</li>
            </ul>
          </div>
          </div>
        </Container>
        <div className={"FooterCTA FooterCTA--" + this.props.country}>
          <CTABtn text="Hacer una recarga" click={this.handleTelInputClick}  />
        </div>
        <Footer country={country} />
      </div>
    )
  }
}

export default HowToCharge