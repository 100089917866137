import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// ACTIONS
import { resetOrder } from '../actions/orderActions'
import { setOrderBeneficiary } from '../actions/orderActions'
import { setOrderOperator } from '../actions/orderActions'
import { resetOperators } from '../actions/operatorActions'
import { resetProducts } from '../actions/operatorActions'
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid';


class Index extends Component {

  constructor(props) {
    super(props)
    document.title = "Cryptorecargas"
    this.props.resetOrder()
    this.props.setOrderBeneficiary('')
    this.props.setOrderOperator({})
    this.props.resetOperators()
    this.props.resetProducts()
  }

  render() {
    return (
      <div>

        <Container>
          <div className="BrandedHeader">
            <div className="BrandedHeader__brand">
              <h1 className="home-logo"><img src="cryptorecargas.svg" alt="Cryptorecargas" /></h1>
            </div>
          </div>
          <div className={'home-illustration--01 home-illustration--01--index'} />
        </Container>
        <div className="home-intro-text-wrapper">
          <div>
            <Container>
              <div className="home-intro-text first">
                <p>Recarga el saldo de tu teléfono móvil de Cuba o Venezuela con Bitcoin, Litecoin, Ether o Bitcoin Cash desde cualquier lugar del mundo y con la mayor seguridad.</p>
              </div>
            </Container>
          </div>
        </div>
        <Container>
          <div className="home-intro-text">
            <p className="bigger">Para empezar, selecciona el país.</p>
          </div>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={6} md={6} align="right">
              <Link className="countrylink" to="/venezuela">
                <img src={'/resources/flag_venezuela.svg'} alt={'Cryptorecargas Venezuela'} width="120" />
                <p>Venezuela</p>
              </Link>
            </Grid>
            <Grid item xs={6} md={6} align="left">
              <Link className="countrylink" to="/cuba">
                <img src={'/resources/flag_cuba.svg'} alt={'Cryptorecargas Cuba'} width="120" />
                <p>Cuba</p>
              </Link>
            </Grid>
          </Grid>
        </Container>
        <div className="home-intro-footer">
          <Container>
            <div className="home-intro-text" mb="4">
              <p>¿Tienes alguna duda?</p>
              <p>Podrás consultar nuestras páginas de preguntas frecuentes y nuestras guía de cómo recargar en sus respectivas secciones de cada país.</p>
            </div>
            <div className="home-foot">
              <p>
                <small><a href="/terminos-y-condiciones">Términos y condiciones</a></small>&nbsp;
                <small><a href="https://www.iubenda.com/privacy-policy/24180238" target="_blank" rel="noopener noreferrer" className="iubenda-nostyle no-brand iubenda-embed" title="Privacy Policy ">Política de privacidad</a></small>
              </p>
              <p>
                <small>&copy; cryptorecargas.com</small>
              </p>
            </div>
          </Container>
        </div>
      </div>
    )
  }
}
Index.propTypes = {
  resetOrder: PropTypes.func.isRequired,
  setOrderBeneficiary: PropTypes.func.isRequired,
  setOrderOperator: PropTypes.func.isRequired,
  resetOperators: PropTypes.func.isRequired,
  resetProducts: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  beneficiary: state.order.beneficiary,
  
})


export default connect(mapStateToProps, {resetOrder, setOrderBeneficiary, setOrderOperator, resetOperators, resetProducts})(Index)