import React, { Component } from 'react'
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/OutlinedInput'
import CTABtn from   '../components/CTABtn'

class SelectorDummy extends Component {
  render() {
    return (
      <div className="SelectorDummy">
        <div className="secondaryOpacityBg">
          <Container>
            <div className="CoinSelector">
              <h3>Pagar en</h3>
              <FormControl variant="outlined" >
                <Select disabled value="">
                </Select>
              </FormControl>
            </div>
          </Container>
        </div>
        <div className="ProductSelector">
          <Container>
            <h3>¿Cuánto quieres recargar?</h3>
            <div className="ProductsWrapper">
              <div>
                <button className="ProductBtn">
                  <div className="bullet">
                    <span />
                  </div>
                  <div className="label">
                    <p className="local">---------- ---</p>
                    <p className="crypto">---------- BTC</p>
                  </div>
                </button>
              </div>
              <div>
                <button className="ProductBtn">
                  <div className="bullet">
                    <span />
                  </div>
                  <div className="label">
                    <p className="local">---------- ---</p>
                    <p className="crypto">---------- BTC</p>
                  </div>
                </button>
              </div>
              <div>
                <button className="ProductBtn">
                  <div className="bullet">
                    <span />
                  </div>
                  <div className="label">
                    <p className="local">---------- ---</p>
                    <p className="crypto">---------- BTC</p>
                  </div>
                </button>
              </div>
              <div>
                <button className="ProductBtn">
                  <div className="bullet">
                    <span />
                  </div>
                  <div className="label">
                    <p className="local">---------- ---</p>
                    <p className="crypto">---------- BTC</p>
                  </div>
                </button>
              </div>
              <div>
                <button className="ProductBtn">
                  <div className="bullet">
                    <span />
                  </div>
                  <div className="label">
                    <p className="local">---------- ---</p>
                    <p className="crypto">---------- BTC</p>
                  </div>
                </button>
              </div>
              <div>
                <button className="ProductBtn">
                  <div className="bullet">
                    <span />
                  </div>
                  <div className="label">
                    <p className="local">---------- ---</p>
                    <p className="crypto">---------- BTC</p>
                  </div>
                </button>
              </div>
              <div>
                <button className="ProductBtn">
                  <div className="bullet">
                    <span />
                  </div>
                  <div className="label">
                    <p className="local">---------- ---</p>
                    <p className="crypto">---------- BTC</p>
                  </div>
                </button>
              </div>
              <div>
                <button className="ProductBtn">
                  <div className="bullet">
                    <span />
                  </div>
                  <div className="label">
                    <p className="local">---------- ---</p>
                    <p className="crypto">---------- BTC</p>
                  </div>
                </button>
              </div>
            </div>
          </Container>
        </div>

        <div className={"secondaryBg secondaryBg--" + this.props.country}>
          <div>
            <Container>
              <div className="EmailFields">
                <h3>Tu email</h3>
                <p>Te enviaremos el detalle completo de la operación.</p>
                <Input
                  type="email"
                  placeholder="tuemail@tuemail.com" 
                  disabled
                />
                <Input
                  type="email"
                  placeholder="tuemail@tuemail.com" 
                  disabled
                />
              </div>
            </Container>
          </div>
          <CTABtn text="pagar" disabled={true} />
        </div>
      </div>
    )
  }
}

export default SelectorDummy