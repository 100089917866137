import React, { Component } from 'react'
import CurrencyFormat from 'react-currency-format'
import {CopyToClipboard} from 'react-copy-to-clipboard';

// COMPS
import TopBar from '../components/TopBar'
import Footer from '../components/Footer'
// MATERIAL UI
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
// Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import LoopIcon from '@material-ui/icons/Loop'

import Countries from '../countries'

let country = ''
class RePlaceOrder extends Component {
  constructor(props) {
    super(props)
    
    country = this.props.match.params.country
    document.title = "Estado de tu transacción | Cryptorecargas " + Countries[country].name
    
    this.state = {
      transaction: {}
    }

    const order_id = this.props.match.params.order_id
    // const proxyUrl = 'https://cors-anywhere.herokuapp.com/'
    const proxyUrl = ''
    let self = this
    fetch(proxyUrl + 'https://api.cryptorecargas.com/api/Order/' + order_id)
      .then(res => res.json())
      .then(function(response){
        self.setState({
          transaction: response
        })
      })

    this.onClick = this.onClick.bind(this)
    this.copyAddress = this.copyAddress.bind(this)
  }

  onClick(order_id) {
    const country = this.props.match.params.country
    this.props.history.push('/' + country + '/transaccion/' + order_id)
  }

  copyAddress(copyTXT) {

  }

  renderTransactionInfo() {
    if(typeof(this.state.transaction.qr) !== 'undefined') {
      const fullad = this.state.transaction.payment_address
      const amount = this.state.transaction.amount_crypto
      const suff = this.state.transaction.currency_crypto === 'DAI' ? ' (incl. fees de la transacción)' : ''
      return (
        <div>
        <div className="place-order-transaction-info">
          <img src={ this.state.transaction.qr } alt="QRCode" />
          <p style={{textAlign: 'center', fontSize: 14}}>{ fullad }</p>
          <div className="place-order-copy-wrapper">
            <CopyToClipboard text={fullad} style={{marginBottom: 16}}>
              <Button className="place-order-copy-btn" variant="contained" color="primary">COPIAR DIRECCIÓN</Button>
            </CopyToClipboard>
            <CopyToClipboard text={amount}>
              <Button className="place-order-copy-btn" variant="contained" color="primary">COPIAR CANTIDAD</Button>
            </CopyToClipboard>
          </div>
        </div>
        <div className="place-order-details">
            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <h3>Detalle de la recarga</h3>
              </AccordionSummary>
              <AccordionDetails>
                <h4>Número de teléfono</h4>
                <p className="field-value">+{this.state.transaction.beneficiary_phone}</p>
                <hr />
                <div className="order-review-operator">
                  <div>
                    <h4>Operador</h4>
                    <p className="field-value">{this.state.transaction.product_name}</p>
                  </div>
                  <div>
                    <img src={'/operators/'+this.state.transaction.product_name.toLowerCase()+'.png'} alt={this.state.transaction.product_name} />
                  </div>
                </div>
                <hr />
                <h4>Cantidad de recarga</h4>
                <div className="order-review__amounts">
                  <p className="local">
                    <CurrencyFormat
                      value={this.state.transaction.amount_local}
                      displayType={'text'}
                      suffix={' ' + Countries[country].currency}
                      thousandSeparator={"."}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      decimalSeparator={","}  />
                  </p>
                  <p className="crypto">
                    <CurrencyFormat
                      value={this.state.transaction.amount_crypto}
                      displayType={'text'}
                      suffix={' ' + this.state.transaction.currency_crypto + suff}
                      thousandSeparator={"."}
                      fixedDecimalScale={true}
                      decimalScale={5}
                      decimalSeparator={","}  />
                  </p>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      )
    } else {
      return (
        <div className="dummy-wrapper relative">
          <p className="info-text" style={{lineHeight: 1.4}}>Cargando datos de la transacción<br/><LoopIcon className="icon-spin dummy-info-icon" /><br />Por favor, espere unos instantes.</p>
        </div>
      )
    }
  }


  render() {
    return (
      <div className="place-order">
        <Container>
          <TopBar text="Realiza tu pago" type="check" showModal={false} country={country} />
        </Container>
        <Container>
          <div className="order-review-wrapper">
            { this.renderTransactionInfo() }
          </div>
          
          <div className="place-order-cta-btn-wrapper">
            <Button className="place-order-cta-btn" onClick={() => {this.onClick(this.props.match.params.order_id)}} variant="outlined" color="primary">YA PAGUÉ</Button>
          </div>
        </Container>
        <Footer country={country} />
      </div>
    )
  }
}

export default RePlaceOrder