import React, { Component } from 'react'

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
class HomeSecureCharge extends Component {
  render() {
    return (
      <div className="home-securecharge">
        <Container>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <h2>Recarga más segura</h2>
              <ul>
                <li>
                  <CheckIcon fontSize="small" />
                  100% asegurada por la tecnología blockchain.
                </li>
                <li>
                  <CheckIcon fontSize="small" />
                  Inmutabilidad y seguridad de los datos.
                </li>
                <li>
                  <CheckIcon fontSize="small" />
                  Garantizamos tu privacidad.
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} md={6}>
              <img src={"/illustrations/home-securecharge--" + this.props.country + ".svg"} alt="Cryptorecargas | Transacción Segura" />
            </Grid>
          </Grid>
        </Container>
      </div>
    )
  }
}

export default HomeSecureCharge