import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import CurrencyFormat from 'react-currency-format'

// BLOCKS
import CTABtn from '../components/CTABtn'
import TopBar from '../components/TopBar'
import Footer from '../components/Footer'
import FeesExplain from '../components/FeesExplain'

// MATERIAL UI
import Container from '@material-ui/core/Container'

import Countries from '../countries'

let country = ''

class ReviewOrder extends Component {
  constructor(props) {
    super(props)
    if(props.order.beneficiary.trim() === '') {
      const country = this.props.match.params.country
      props.history.push('/' + country + '/operador')
    } else {
    }
    country = this.props.match.params.country
    document.title = "Revisa los detalles de tu transacción " + Countries[country].name
  }  

  handleCTAClick = () => {
    const country = this.props.match.params.country
    this.props.history.push('/' + country + '/pagar')
  }

  render() {
    const suff = this.props.order.amount.cryptoUnit === 'DAI' ? ' + Fees' : ''
    const Fees = this.props.order.amount.cryptoUnit === 'DAI' ? <FeesExplain /> : <></>
    return (
      <div className="ReviewOrderPage">
        <Container>
          <TopBar text="Recargas" hasBack={true} hasModal={false} country={country} />
          <div className="ReviewOrderWrapper">
            <p className="info-text">Revisa que todos los detalles de la operación son correctos para terminar tu pedido.</p>
            <h4>Número de teléfono</h4>
            <p className="field-value">+{Countries[country].intcode} {this.props.order.beneficiary}</p>
            <hr />
            <div className="order-review-operator">
              <div>
                <h4>Operador</h4>
                <p className="field-value">{this.props.order.operator.product_name}</p>
              </div>
              <div>
                <img src={'/operators/'+this.props.order.operator.product_name.toLowerCase()+'.png'} alt={this.props.order.operator.product_name} />
              </div>
            </div>
            <hr />
            <h4>Cantidad de recarga</h4>
            <div className="order-review__amounts">
              <p className="local">
                <CurrencyFormat
                  value={this.props.order.amount.local}
                  displayType={'text'}
                  suffix={' ' + Countries[country].currency}
                  thousandSeparator={"."}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  decimalSeparator={","}  />
              </p>
              <p className="crypto">
                <CurrencyFormat
                  value={this.props.order.amount.crypto}
                  displayType={'text'}
                  suffix={' ' + this.props.order.amount.cryptoUnit + suff}
                  thousandSeparator={"."}
                  fixedDecimalScale={true}
                  decimalScale={5}
                  decimalSeparator={","}  />
              </p>
            </div>
            <hr />
            <CTABtn text="Validar datos" click={this.handleCTAClick} />
            {Fees}
          </div>
        </Container>
        <Footer country={country} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    order: state.order
  };
};

export default connect(mapStateToProps, {})(withRouter(ReviewOrder))