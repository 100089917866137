// ORDER
export const SET_ORDER_BENEFICIARY = 'SET_ORDER_BENEFICIARY'
export const SET_ORDER_OPERATOR = 'SET_ORDER_OPERATOR'
export const SET_ORDER_CURRENCY = 'SET_ORDER_CURRENCY'
export const SET_ORDER_RATE = 'SET_ORDER_RATE'
export const SET_ORDER_AMOUNT = 'SET_ORDER_AMOUNT'
export const SET_ORDER_EMAIL = 'SET_ORDER_EMAIL'
export const SET_ORDER_ID = 'SET_ORDER_ID'
export const RESET_ORDER = 'RESET_ORDER'

// OPERATORS
export const FETCH_OPERATORS = 'FETCH_OPERATORS'
export const RESET_OPERATORS = 'RESET_OPERATORS'
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
export const RESET_PRODUCTS = 'RESET_PRODUCTS'
// CURRENCIES
export const FETCH_CURRENCIES = 'FETCH_CURRENCIES'