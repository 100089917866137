import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom'

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

class HomeInfo extends Component {
  render() {
    return (
      <div className={"home-info home-info--" + this.props.country}>
        <div className="home-info-wrapper">
          <Container>
            <h2>Resolvemos tus dudas</h2>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <p className="question"><strong>¿Cómo recargo un móvil en {this.props.countryName}?</strong></p>
                <p className="answer">Si quieres recargar un móvil a tus familiares o amigos en {this.props.countryName} desde aquí es muy sencillo y cómodo.  Solo tienes que indicarnos el número de teléfono del beneficiario y el operador. Luego podrás elegir el paquete y la criptomoneda con la que desees realizar la recarga.</p>
              </Grid>
              <Grid item xs={12} md={4}>
                <p className="question"><strong>¿Cuánto tiempo tarda en hacerse la recarga en el teléfono de {this.props.countryName}?</strong></p>
                <p className="answer">Tus pedidos son entregados tan pronto como el pago esté asegurado. Generalmente, esto tarda entre 0 y 10 minutos, dependiendo de la criptomoneda que hayas elegido.</p>
              </Grid>
              <Grid item xs={12} md={4}>
                <p className="question"><strong>¿Tienes más dudas?</strong></p>
                <p className="answer"><Link to={"/" + this.props.country + "/preguntas-frecuentes"}>Consulta nuestras preguntas frecuentes.</Link></p>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    )
  }
}

export default withRouter(HomeInfo)