import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'


// COMPONENTS
import CTABtn from './CTABtn'

class FooterCTA extends Component {

  handleTelInputClick = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    setTimeout(function(){
      document.getElementById("TelInput").focus();
    }, 500); 
    // this.props.history.push('/operador')
  }

  render() {
    return (
      <div className={"FooterCTA FooterCTA--" + this.props.country}>
        <CTABtn text="Hacer una recarga" click={this.handleTelInputClick}  />
      </div>
    )
  }
}

export default withRouter(FooterCTA)